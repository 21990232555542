import find from "lodash/find";
import map from "lodash/map";
import uuid from "uuid/v4";
import { includesCaseInsensitive } from "../components/Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import { metadataService as defaultMetadataService } from "./metadata.service";

export const mapperMappingService = {
    getAttributesMetadata: async function (
        sourceType,
        sourceId,
        entityType,
        entityName,
        metadataService = defaultMetadataService,
        queryParams,
    ) {
        const { data } = await metadataService.fetchAttributesMetadata(
            sourceType,
            sourceId,
            entityType,
            entityName,
            queryParams,
        );
        return data;
    },

    mapTableDataToMetadata(tableData, outputOptions) {
        const columns = tableData?.columns?.map(c => c.name) ?? [];
        const mappedItems = this.mapColumnsToMetadata(columns, outputOptions);

        return mappedItems;
    },

    mapColumnsToMetadata(columnNames, outputOptions) {
        return map(columnNames, columnName => {
            const option = find(outputOptions, outputOption =>
                includesCaseInsensitive(outputOption.label, columnName),
            );
            if (option) {
                return {
                    id: uuid(),
                    input: columnName,
                    inputType: "body",
                    output: option.value,
                    outputType: option.type,
                    outputLabel: option.label,
                };
            }
            return {
                id: uuid(),
                input: columnName,
                inputType: "body",
            };
        });
    },
};
