import { ConfigureStep } from "@/components/DataUploads/Wizard/NewTable/components/ConfigureStep";
import { CreateCPStep } from "@/components/DataUploads/Wizard/NewTable/components/CreateCPStep";
import { CreateExtensionStep } from "@/components/DataUploads/Wizard/NewTable/components/CreateExtensionStep";
import { UCLayoutWithSteps } from "@/components/DataUploads/Wizard/NewTable/components/UCLayoutWithSteps";
import { Forms, Modal } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const CREATE_TABLE_SUPPORTED_TYPES = ["PX", "CX", "SX", "MLTV"];

export const NewTableModal = ({
    entityTypeNames,
    visible,
    partitionId,
    tableType,
    afterSave,
    afterFinish,
    onClose,
}) => {
    const [tableParams, setTableParams] = useState({});
    useEffect(() => {
        if (!visible) setTableParams({});
    }, [visible]);

    const mapStepProps = {
        visible,
        partitionId,
        tableType,
        tableParams,
        setTableParams,
        entityTypeNames,
        onCancel: onClose,
        afterSave,
        afterFinish,
    };
    const CreateStepComponent = ["PX", "CX", "SX"].includes(tableType)
        ? CreateExtensionStep
        : ["MLTV"].includes(tableType)
        ? CreateCPStep
        : undefined;

    if (!visible) return null;

    if (!CreateStepComponent)
        throw new Error(
            `Unsupported type "${tableType}", supported: ${CREATE_TABLE_SUPPORTED_TYPES.join(
                ",",
            )}`,
        );

    return (
        <div onSubmit={e => e.stopPropagation()}>
            <Modal visible={visible} onClose={onClose}>
                <UCLayoutWithSteps
                    title={t("table-metadata-form.button.new")}
                    initialStepIndex={0}
                    mapStepProps={stepperProps => ({
                        ...stepperProps,
                        ...mapStepProps,
                    })}
                    steps={[
                        {
                            title: t("new-table.step-create.title"),
                            Component: CreateStepComponent,
                        },
                        {
                            title: t("new-table.step-configure.title"),
                            Component: ConfigureStep,
                        },
                    ]}
                />
            </Modal>
        </div>
    );
};

NewTableModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    afterSave: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    tableType: PropTypes.string,
    entityTypeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
