import { Tabs } from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { PageLayoutContext } from "@/components/PageLayout/PageLayoutContext";
import { omit, set, update } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useRoute } from "react-router5";
import uuid from "uuid/v4";

const throwOnMultipleHeaderTabs = next => previous => {
    if (previous?.id && previous.id !== next.id)
        throw new Error("HeaderTabs can be used only once!");
    return next;
};

export const useHeaderTabs = () => {
    const { headerTabs, setHeaderTabs } = useContext(PageLayoutContext);

    const activeKey = headerTabs?.tabsProps?.activeKey;
    const setActiveKey = useCurrentHandler(tabOrFn =>
        typeof tabOrFn === "function"
            ? setHeaderTabs(update(["tabsProps", "activeKey"], tabOrFn))
            : setHeaderTabs(set(["tabsProps", "activeKey"], tabOrFn)),
    );

    return {
        headerTabs,
        setHeaderTabs,
        activeKey,
        setActiveKey,
    };
};

const isVisible = child =>
    !!child?.key &&
    (child?.props?.visible || child?.props?.visible === undefined);

const getActiveKey = (visibleKeys, keys) => {
    const key = keys
        .filter(Boolean)
        .filter(key => visibleKeys.includes(key))[0];
    return key || visibleKeys[0];
};

export const HeaderTabs = ({
    children,
    defaultActiveKey, // when not specified, first visible is used
    onChange,
    locationParam = "tab", // Add "?tab" to location url to enable, pass false to disable
    ...props
}) => {
    const [id] = useState(uuid());
    const { activeKey, setActiveKey, headerTabs, setHeaderTabs } =
        useHeaderTabs();

    const onChangeTab = useCurrentHandler(tab => {
        setActiveKey(tab);
        onChange?.(tab);
    });

    const tabPanes = React.Children.toArray(children)
        .filter(child => isVisible(child))
        .map(child => ({
            key: child.key.slice(2),
            ...omit(["children"], child.props),
        }));
    const visibleKeys = useMemoByDeepEquality(tabPanes.map(({ key }) => key));
    const nextHeaderTabs = useMemoByDeepEquality({
        id,
        tabsProps: {
            activeKey: getActiveKey(visibleKeys, [activeKey, defaultActiveKey]),
            onChange: onChangeTab,
            fullSize: true,
            splitLayout: true,
            ...props,
        },
        tabPanes,
    });

    useEffect(() => {
        setHeaderTabs(throwOnMultipleHeaderTabs(nextHeaderTabs));
    }, [nextHeaderTabs, setHeaderTabs]);
    useEffect(() => () => setHeaderTabs(undefined), [setHeaderTabs]);

    const { route, router } = useRoute();
    const activeKeyParam = locationParam
        ? route.params[locationParam]
        : undefined;

    useEffect(() => {
        // route -> state
        if (activeKeyParam)
            setActiveKey(getActiveKey(visibleKeys, [activeKeyParam]));
    }, [activeKeyParam, setActiveKey, visibleKeys]);

    const setActiveKeyParam = useCurrentHandler(param => {
        if (locationParam)
            router.navigate(route.name, {
                ...router.getState().params,
                [locationParam]: param,
            });
    });
    useEffect(() => {
        // state -> route
        if (activeKey) setActiveKeyParam(activeKey);
    }, [activeKey, setActiveKeyParam]);

    if (!headerTabs) return null;

    return <Tabs {...headerTabs.tabsProps}>{children}</Tabs>;
};

HeaderTabs.propTypes = {
    children: PropTypes.node,
    defaultActiveKey: PropTypes.node,
    onChange: PropTypes.func,
    locationParam: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

HeaderTabs.TabPane = Tabs.TabPane;
HeaderTabs.useHeaderTabs = useHeaderTabs;
