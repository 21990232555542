import { Forms, Gap, Text } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import React from "react";
const { useForm, Form, Fields, pmValidators } = Forms;

export const TargetStep = ({
    transportsEnvListResource,
    instanceName,
    useFooterButtons,
    onCancel,
    nextStep,
    setTargetEnvironment,
}) => {
    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values: { targetEnvironment } }) => {
            setTargetEnvironment(targetEnvironment);
            nextStep();
        },
    });
    useFooterButtons([
        {
            label: t("general.continue"),
            type: "primary",
            formId,
        },
        {
            label: t("general.cancel"),
            type: "text",
            onClick: onCancel,
        },
    ]);

    return (
        <>
            <Text>
                {t("instances.transport.to.modal.content", {
                    instanceName: (
                        <>
                            <br />
                            <b>{instanceName}</b>
                        </>
                    ),
                })}
            </Text>
            <Gap />
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Select
                    label={t("instances.transport.to.modal.form.target.label")}
                    placeholder={t(
                        "instances.transport.to.modal.form.target.placeholder",
                    )}
                    name="targetEnvironment"
                    required
                    validator={pmValidators.isRequired}
                    inputWidth="max"
                    autoComplete="off"
                    {...getLoadableSelectProps(
                        transportsEnvListResource.loadable,
                    )}
                />
            </Form>
        </>
    );
};
