import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop, tap } from "lodash/fp";

export const getSchedulerSuccessMsg = ({ enabled, name }) =>
    t("general.name-saved-as", {
        name,
        value: enabled ? "Active" : "Inactive",
    });

export const useEventSchedulersQuery = ({ accountId, reloadToken }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-schedulers`)
                .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken, accountId, axiosService],
    );
};

export const useCreateEventSchedulerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/accounts/${accountId}/event-schedulers`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
        getSchedulerSuccessMsg,
    );
};

export const useEventSchedulerQuery = ({ accountId, schedulerId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}`,
                )
                .then(getData),
        [accountId, axiosService, schedulerId],
    );
};

export const useUpdateEventSchedulerMutation = ({
    accountId,
    schedulerId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}`,
                    payload,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService, schedulerId],
        getSchedulerSuccessMsg,
    );
};

export const useDeleteEventSchedulerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ schedulerId }) =>
            axiosService
                .delete(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
    );
};

export const useChangeEventSchedulerStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ schedulerId, enabled, accountId }) =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}/enable`,
                    { enabled },
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
        getSchedulerSuccessMsg,
    );
};
