import { UCLayoutWithSteps } from "@/components/DataUploads/Wizard/NewTable/components/UCLayoutWithSteps";
import { Modal } from "@/components/DesignSystem";
import { SelectObjectsStep } from "@/components/Integrations/components/TransportModal/steps/SelectObjectsStep";
import { SelectPropertiesStep } from "@/components/Integrations/components/TransportModal/steps/SelectPropertiesStep";
import { TargetStep } from "@/components/Integrations/components/TransportModal/steps/TargetStep";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const TransportSelectedToModal = ({
    transportsEnvListResource,
    instanceId,
    instanceName,
    visible,
    onFinish,
    onCancel,
}) => {
    const [alert, setAlert] = useState(null);
    const [targetEnvironment, setTargetEnvironment] = useState();
    const [sourceEnvironment, setSourceEnvironment] = useState();
    const [transportObjects, setTransportObjects] = useState();

    return (
        <Modal width="800px" visible={visible} onClose={onCancel}>
            <UCLayoutWithSteps
                title={t("instances.transport.to.modal.title")}
                initialStepIndex={0}
                alert={alert}
                steps={[
                    {
                        title: t("instances.transport.steps.target.title"),
                        Component: TargetStep,
                        mapStepProps: stepperProps => ({
                            ...stepperProps,
                            transportsEnvListResource,
                            instanceName,
                            onCancel,
                            setTargetEnvironment,
                        }),
                    },
                    {
                        title: t("instances.transport.steps.objects.title"),
                        Component: SelectObjectsStep,
                        mapStepProps: stepperProps => ({
                            ...stepperProps,
                            instanceId,
                            instanceName,
                            setAlert,
                            // onFinish,
                            onCancel,
                            targetEnvironment,
                            setSourceEnvironment,
                            setTransportObjects,
                        }),
                    },
                    {
                        title: t("instances.transport.steps.properties.title"),
                        Component: SelectPropertiesStep,
                        mapStepProps: stepperProps => ({
                            ...stepperProps,
                            instanceId,
                            instanceName,
                            setAlert,
                            onFinish,
                            onCancel,
                            targetEnvironment,
                            transportObjects,
                            setSourceEnvironment,
                        }),
                    },
                ]}
            />
        </Modal>
    );
};

TransportSelectedToModal.propTypes = {
    transportsEnvListResource: PropTypes.object.isRequired,
    instanceId: PropTypes.number.isRequired,
    instanceName: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onFinish: PropTypes.func,
    onCancel: PropTypes.func,
};
