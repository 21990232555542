import { useSetTableFieldTypesMutation } from "@/components/DataUploads/Wizard/loadables";
import { Forms, Gap, Table, Text } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { useFieldKeysQuery } from "@/components/Filters/form/loadables";
import { naturallySortByCategories } from "@/components/Mappers/MapperTableWithCustomization/loadableResurces";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import { REQUIRED_BY_ENTITY_TYPE } from "@/constants/SupportedTableImportTypes";
import { isLoading } from "@/modules/loadable";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { entries, get, map, pipe, reduce, set } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useMemo, useReducer } from "react";
import "../../../../FeatureFlags/AdminFeatureFlagsPage.style.less";

const toName = ([name, property]) => JSON.stringify([name, property]);
const fromName = string => JSON.parse(string);

export const TableCellField = React.memo(
    ({
        Component = Forms.Field,
        uniquePredicate,
        validator: validatorProp,
        forceRevalidateToken,
        ...props
    }) => {
        const validator = useMemo(
            () =>
                Forms.validators.failOnFirst(
                    [
                        validatorProp,
                        uniquePredicate &&
                            Forms.pmValidators.createUniqueValueValidation(
                                uniquePredicate,
                            ),
                    ].filter(Boolean),
                ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [uniquePredicate, validatorProp, forceRevalidateToken],
        );
        return (
            <StopOnKeyDownPropagation>
                <Component
                    label={""}
                    size="small"
                    inputWidth="max"
                    onClick={e => e.stopPropagation()}
                    validator={validator}
                    {...props}
                />
            </StopOnKeyDownPropagation>
        );
    },
);

TableCellField.propTypes = {
    Component: PropTypes.elementType,
    uniquePredicate: PropTypes.func,
    validator: PropTypes.func,
    forceRevalidateToken: PropTypes.any,
};

const DEFAULT_TYPE_VALUE = 2;
const TYPE_OPTIONS = [
    { value: 1, label: "Real" },
    { value: 2, label: "String" },
    { value: 3, label: "Int" },
    { value: 4, label: "Date" },
    { value: 5, label: "Datetime" },
    { value: 6, label: "Link" },
    { value: 7, label: "CalcresultReference" },
    { value: 8, label: "EntityReference" },
    { value: 9, label: "Boolean" },
];

TableCellField.propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    enableHighlight: PropTypes.bool,
};

const isLabel = ({ name, value }) => fromName(name)[1] === "label";

const createColumns = ({
    tableType,
    revalidate,
    forceRevalidateToken,
    forbidChangeFieldTypes,
}) => [
    {
        type: fieldTypes.TEXT,
        label: t("new-table.step-configure.column.name"),
        name: "name",
        className: "pmTable-centeredCell",
    },
    {
        type: fieldTypes.TEXT,
        label: t("new-table.step-configure.column.label"),
        name: "label",
        render: (text, record) => {
            return (
                <TableCellField
                    Component={Forms.Fields.Input}
                    name={toName([record.name, "label"])}
                    required
                    validator={Forms.pmValidators.isRequired}
                    uniquePredicate={isLabel}
                    allowClear={false}
                    initialValue={text}
                    onBlur={revalidate}
                    forceRevalidateToken={forceRevalidateToken}
                />
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("new-table.step-configure.column.type"),
        name: "type",
        render: (text, record) => {
            const disabled =
                forbidChangeFieldTypes ||
                REQUIRED_BY_ENTITY_TYPE[tableType]?.includes(record.name);

            return (
                <TableCellField
                    Component={Forms.Fields.Select}
                    name={toName([record.name, "fieldType"])}
                    options={TYPE_OPTIONS}
                    required
                    disabled={disabled}
                    validator={Forms.pmValidators.isRequired}
                    allowClear={false}
                    initialValue={
                        record.originalField?.fieldType || DEFAULT_TYPE_VALUE
                    }
                />
            );
        },
    },
];

export const ConfigureStep = ({
    useFooterButtons,
    nextStep,
    prevStep,

    visible,
    partitionId,
    tableType,
    tableParams,
    onCancel,
    afterFinish,
}) => {
    const fieldsQuery = useFieldKeysQuery({
        objectType: mapperMappingSourceType.partitions,
        objectId: partitionId,
        entityType: tableType,
        entityName: tableParams?.name,
        canFetch: !!tableParams?.name,
        map: naturallySortByCategories,
    });
    const setFieldsTypesMutation = useSetTableFieldTypesMutation({
        partitionId,
        // entityType: tableType,
        entityType: tableParams?.type === "JSON" ? "JLTV" : tableType,
        tableParams,
        afterSave: afterFinish,
    });
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: pipe(
            get("values"),
            entries,
            reduce((acc, [name, val]) => set(fromName(name), val, acc), {}),
            entries,
            map(([fieldName, values]) => ({ fieldName, ...values })),
            setFieldsTypesMutation.mutate,
        ),
    });
    useFooterButtons([
        {
            label: t("general.create"),
            type: "primary",
            formId,
        },
        {
            label: t("general.cancel"),
            type: "text",
            onClick: onCancel,
        },
    ]);
    const [forceRevalidateToken, revalidate] = useReducer(i => i + 1, 0);
    const columns = useMemo(
        () =>
            createColumns({
                tableType,
                revalidate,
                forceRevalidateToken,
                forbidChangeFieldTypes: tableParams?._forbidChangeFieldTypes,
            }),
        [forceRevalidateToken, tableParams?._forbidChangeFieldTypes, tableType],
    );
    const dataSource = fieldsQuery.loadable.valueMaybe() || [];

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Text>{t("new-table.step-configure.perex")}</Text>
            <Gap size="small" />
            <DisableSubmitOnEnter>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="name"
                    className="pmTable--withInputs"
                    loading={isLoading(fieldsQuery)}
                    rowHeight={40}
                />
            </DisableSubmitOnEnter>
        </Forms.Form>
    );
};
