import { Button, Link } from "@/components/DesignSystem";
import {
    NotificationType,
    TaskType,
} from "@/components/Header/components/Notifications/types";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import { WorkflowType } from "@/constants/CommonConstats";
import { t } from "@/translations";
import uuid from "uuid/v4";
const { useDic } = require("@/components/Dic/useDic.hook");
const { useUserBasicInfo } = require("@/security/hooks/useUserBasicInfo.hook");
import React from "react";

const messageByTaskType = task => {
    switch (task.type) {
        case TaskType.SOLUTION_DEPLOY:
        case TaskType.SOLUTION_DELETE:
        case TaskType.UPLOAD_FILE:
        case TaskType.DOWNLOAD_FILE:
        case TaskType.INTEGRATION_TEMPLATE_DEPLOY:
            return `${t(`notifications.message.${task.type}`)} ${task.status}`;

        default:
            return `${task.type} ${task.status}`;
    }
};

export const titleByNotificationType = notification => {
    switch (notification.type) {
        case NotificationType.TASK:
            return messageByTaskType(notification.data);
        case NotificationType.PACKAGE_ROLLBACK:
        case NotificationType.PACKAGE_TERMINATION:
            const packageInfo = JSON.parse(notification.data);
            return t(`notifications.message.${notification.type}`, {
                templateName: packageInfo.templateName,
                partitionName: packageInfo.partition,
            });
        case NotificationType.WORKFLOW_APPROVAL:
            return t(`notifications.message.workflow.approval`, {
                type: WorkflowType.translate(
                    notification.data?.workflowRequestData?.wfType,
                ),
            });
        case NotificationType.TRANSPORT_IM_ENVIRONMENT:
            return `${t(`notifications.message.${notification.type}`, {
                instanceName: notification.data?.instanceName,
                sourceBranch: notification.data?.sourceBranch,
                targetBranch: notification.data?.targetBranch,
            })}`;
        case NotificationType.INSTANCE_DELETED:
            return `${t(`notifications.message.${notification.type}`, {
                instanceName: notification.value,
            })}`;
        default:
            return `${notification.type} received`;
    }
};

const defaultWrap = text => <Link>{text}</Link>;
export const messageByNotificationType = (notification, map = defaultWrap) => {
    if (notification.type === NotificationType.TASK) {
        const task = notification.data;
        if (task.type === TaskType.DOWNLOAD_FILE && task.context?.fileName)
            return map(task.context?.fileName);
    }

    return undefined;
};

export const useNotificationActions = () => {
    const {
        locationRouterService,
        locations: { tasksLocation, myApprovalsLocation },
        accountAppLocations: {
            partitionUploadHistoryLocation,
            instancesLocationWithTransport,
            accountDeploymentLogLocation,
            partitionDataManagementLocation,
        },
        marketplaceAppLocations: { packageDetailLocation },
    } = useDic();

    const basicInfo = useUserBasicInfo();

    const onClickByNotificationType = notification => {
        switch (notification.type) {
            case NotificationType.TASK: {
                const context = notification.data.context;

                if (TaskType.DOWNLOAD_FILE === notification.data.type) {
                    return () => {
                        locationRouterService.navigate(
                            partitionDataManagementLocation,
                            {
                                tab: PDM_TABS.DOWNLOADS,
                                ...context,
                            },
                        );
                    };
                } else if (TaskType.UPLOAD_FILE === notification.data.type) {
                    return () => {
                        locationRouterService.navigate(
                            partitionUploadHistoryLocation,
                            context,
                        );
                    };
                } else if (TaskType.PACKAGE_DEPLOY === notification.data.type) {
                    return () =>
                        locationRouterService.navigate(
                            accountDeploymentLogLocation,
                            context,
                        );
                } else {
                    return () => {
                        locationRouterService.navigate(tasksLocation, {
                            status: notification.data?.status,
                        });
                    };
                }
            }

            case NotificationType.TRANSPORT_IM_ENVIRONMENT:
                return () => {
                    const forceReloadParam = uuid();
                    const data = notification.data;

                    locationRouterService.navigate(
                        instancesLocationWithTransport,
                        {
                            accountId: data?.projectId,
                            checkTransportFor: data?.instanceId,
                            mergeRequestId: data?.mergeRequestId,
                            forceReloadParam,
                        },
                    );
                };

            case NotificationType.WORKFLOW_APPROVAL:
                return () => {
                    locationRouterService.navigate(myApprovalsLocation);
                };
            case NotificationType.PACKAGE_ROLLBACK:
            case NotificationType.PACKAGE_TERMINATION:
                return () => {
                    const data = JSON.parse(notification.data);
                    locationRouterService.navigate(packageDetailLocation, {
                        accountId: basicInfo.userInfo.user.defaultProjectId,
                        partitionName: data.partition,
                    });
                };
            default:
                return undefined;
        }
    };

    return {
        onClickByNotificationType,
        titleByNotificationType,
    };
};
