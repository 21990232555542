import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Gap, Link, Table, UnityPopover } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import {
    usePartitionQueueEventsDelete,
    usePartitionQueueEventsQuery,
    usePartitionQueueScheduledEventsDelete,
    usePartitionQueueScheduledEventsQuery,
} from "@/components/UploadQueue/loadables";
import { CODE_TO_LABEL } from "@/constants/codeToLabel";
import { t } from "@/translations";
import Icon from "@ant-design/icons";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import { ReactComponent as InfoCircle } from "@pricefx/unity-components/dist/es/icons/unicons/info-circle.svg";
import React from "react";

const STATUS = { WAITING: "WAITING", IN_PROCESS: "IN_PROCESS" };

const STATUS_LABEL = {
    IN_PROCESS: t("partition.upload-queue.list.status.in-process"),
    WAITING: t("partition.upload-queue.list.status.waiting"),
};

const codeToLabel = code => CODE_TO_LABEL[code] || code;

const addAlert = row =>
    row.status === STATUS["WAITING"]
        ? {
              alerts: {
                  status: "Waiting",
                  status_ALERT_TYPE: "YELLOW",
              },
          }
        : {};

const StatusIcon = ({ id, type, fileName, onRemove }) => {
    switch (type) {
        case STATUS["WAITING"]:
            return (
                <UnityPopover
                    title={t("partition.upload-queue.popover.waiting.title")}
                    content={
                        <>
                            {t(
                                "partition.upload-queue.popover.waiting.content",
                                { fileName },
                            )}
                            <Gap />
                            <Link
                                onClick={() => onRemove({ id })}
                                className="pmActionButtonItem--red"
                            >
                                {t(
                                    "partition.upload-queue.popover.waiting.stop",
                                )}
                            </Link>
                        </>
                    }
                    placement="bottom"
                >
                    <Icon
                        component={ExclamationTriangleSolid}
                        style={{
                            color: "#FAAD14",
                            fontSize: "15px",
                        }}
                    />
                </UnityPopover>
            );
        case STATUS["IN_PROCESS"]:
            return (
                <UnityPopover
                    title={t(
                        "partition.upload-queue.popover.in-progress.title",
                    )}
                    content={
                        <>
                            {t(
                                "partition.upload-queue.popover.in-progress.content",
                                { fileName },
                            )}
                            <Gap />
                            <Link
                                onClick={() => onRemove({ id })}
                                className="pmActionButtonItem--red"
                            >
                                {t(
                                    "partition.upload-queue.popover.in-progress.stop",
                                )}
                            </Link>
                        </>
                    }
                    placement="bottom"
                >
                    <Icon
                        component={InfoCircle}
                        style={{
                            color: "#0078A2",
                            fontSize: "15px",
                        }}
                    />
                </UnityPopover>
            );
        default:
            break;
    }
};

const createColumns = ({ onRemove, onRemoveScheduled }) => [
    {
        name: "_order",
        label: t("partition.upload-queue.list.order"),
        type: fieldTypes.TEXT,
        canFilter: false,
        width: 55,
    },
    {
        name: "name",
        label: t("partition.upload-queue.list.name"),
        type: fieldTypes.TEXT,
        render: (text, record) => (
            <>
                {text}
                <ActionButton
                    record={record}
                    items={[
                        {
                            title:
                                record.status === STATUS["IN_PROCESS"]
                                    ? t(
                                          "partition.upload-queue.list.force-stop",
                                      )
                                    : t(
                                          "partition.upload-queue.list.remove-from-queue",
                                      ),
                            onClick: () =>
                                record._scheduled
                                    ? onRemoveScheduled({
                                          id: record.id,
                                      })
                                    : onRemove({
                                          id: record.id,
                                      }),
                            color: "red",
                        },
                    ]}
                />
            </>
        ),
        canFilter: false,
    },
    {
        name: "fileName",
        label: t("partition.upload-queue.list.file-name"),
        type: fieldTypes.TEXT,
        canFilter: false,
    },
    {
        name: "type",
        label: t("partition.upload-queue.list.type"),
        type: fieldTypes.TEXT,
        canFilter: false,
    },
    {
        name: "entityType",
        label: t("partition.upload-queue.list.entity-type"),
        type: fieldTypes.TEXT,
        canFilter: false,
        render: code => codeToLabel(code),
    },
    {
        name: "entityName",
        label: t("partition.upload-queue.list.entity-name"),
        type: fieldTypes.TEXT,
        canFilter: false,
        width: 95,
    },
    {
        name: "executionTime",
        label: t("partition.upload-queue.list.executionTime"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.executionTime && (
                <FormattedDateTime>{record.executionTime}</FormattedDateTime>
            ),
        canFilter: false,
    },
    {
        name: "status",
        label: t("partition.upload-queue.list.status"),
        type: fieldTypes.OPTION,
        options: [
            { value: "IN_PROCESS", label: "In progress" },
            { value: "WAITING", label: "Waiting" },
        ],
        render: (text, record) => {
            if (text === STATUS["WAITING"] || text === STATUS["IN_PROCESS"]) {
                return (
                    <>
                        <StatusIcon
                            type={text}
                            id={record.id}
                            fileName={record.fileName}
                            onRemove={
                                record._scheduled ? onRemoveScheduled : onRemove
                            }
                        />{" "}
                        {STATUS_LABEL[text] ?? text}
                    </>
                );
            }
            return STATUS_LABEL[text] ?? text;
        },
        canFilter: false,
        width: 105,
    },
];

export const UploadQueue = ({ partitionId }) => {
    const partitionQueueEventsQuery = usePartitionQueueEventsQuery({
        partitionId,
    });

    const partitionQueueEventsDelete = usePartitionQueueEventsDelete({
        partitionId,
        afterSuccess: partitionQueueEventsQuery.reload,
    });

    const partitionQueueScheduledEventsQuery =
        usePartitionQueueScheduledEventsQuery({
            partitionId,
        });

    const partitionQueueScheduledEventsDelete =
        usePartitionQueueScheduledEventsDelete({
            partitionId,
            afterSuccess: partitionQueueScheduledEventsQuery.reload,
        });

    const dataSourceWithAlerts = [
        ...(partitionQueueEventsQuery.loadable.valueMaybe() ?? []),
        ...(partitionQueueScheduledEventsQuery.loadable.valueMaybe() ?? []),
    ].map((row, index) => ({
        ...row,
        _order: index + 1,
        ...addAlert(row),
    }));

    useRefreshAction(() => {
        partitionQueueEventsQuery.reload();
        partitionQueueScheduledEventsQuery.reload();
    });

    return (
        <Table
            columns={createColumns({
                onRemove: partitionQueueEventsDelete.mutate,
                onRemoveScheduled: partitionQueueScheduledEventsDelete.mutate,
            })}
            dataSource={dataSourceWithAlerts}
            rowKey="id"
        />
    );
};
