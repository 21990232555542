import {
    Badge,
    ButtonMenu,
    UnityIcon,
    UnityLayout,
    UnityMenu,
    UnityPopover,
    UnityResult,
    UnityTooltip,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    messageByNotificationType,
    useNotificationActions,
} from "@/components/Header/components/Notifications/useNotificationActions.hook";
import {
    useNotificationControls,
    useNotificationListener,
    useNotificationsValue,
} from "@/components/Header/components/Notifications/useNotificationsControls.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { t } from "@/translations";
import { ReactComponent as IconEmpty } from "@pricefx/unity-components/dist/es/icons/svg/empty-default.svg";
import { ReactComponent as IconBell } from "@pricefx/unity-components/dist/es/icons/unicons/bell.svg";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./HeaderNotifications.style.less";
import { HeaderNotificationsItem } from "./HeaderNotificationsItem";

const EmptyScreen = () => (
    <UnityResult
        icon={<IconEmpty viewBox="0 0 200 200" />}
        subTitle="sfdc_enjoyDay"
        title="sfdc_nofification_empty"
    />
);

const HeaderIcon = () => {
    const [notifications] = useNotificationsValue();
    const hasUnseenNotifications = notifications.some(
        notification => notification.readAt === null,
    );
    return (
        <Badge dot={hasUnseenNotifications}>
            <UnityIcon
                component={IconBell}
                style={{
                    fontSize: 16,
                    margin: 0,
                }}
                title={t("header.notifications.title")}
            />
        </Badge>
    );
};

export const useHeaderItemNotifications = () => {
    const popover = useVisibility(false);
    // Listen for SSE and first load
    useNotificationListener();

    return {
        component: () => (
            <UnityMenu.ItemContent
                customOnClick={popover.show}
                data-test="notifications"
            >
                <UnityPopover
                    arrowHidden
                    content={<HeaderNotifications onClose={popover.hide} />}
                    onVisibleChange={isOpen => {
                        if (!isOpen) {
                            popover.hide();
                        }
                    }}
                    visible={popover.visible}
                    padding={false}
                    placement="bottomRight"
                    trigger="click"
                >
                    <UnityTooltip tooltip="sfdc_nofification_label">
                        <HeaderIcon />
                    </UnityTooltip>
                </UnityPopover>
            </UnityMenu.ItemContent>
        ),
        key: "notifications",
        title: "Notifications",
    };
};

const HeaderNotifications = ({ onClose }) => {
    const {
        notifications,
        isLoading,
        hasNotifications,
        onScroll,
        markAllAsRead,
        markAsRead,
    } = useNotificationControls();

    const {
        locationRouterService,
        locations: { notificationsLocation },
    } = useDic();

    const actionButtons = useMemo(
        () =>
            hasNotifications ? (
                <ButtonMenu
                    buttons={[
                        {
                            label: t("notifications.mark-all-as-read"),
                            onClick: event => {
                                event.stopPropagation();
                                markAllAsRead();
                                onClose();
                            },
                            type: "link",
                        },
                    ]}
                />
            ) : (
                []
            ),
        [hasNotifications, markAllAsRead, onClose],
    );

    const footerActionButtons = useMemo(
        () => (
            <ButtonMenu
                buttons={[
                    {
                        label: t("header.footer.view-all.title"),
                        onClick: event => {
                            event.stopPropagation();
                            locationRouterService.navigate(
                                notificationsLocation,
                            );
                            onClose();
                        },
                        type: "link",
                    },
                ]}
            />
        ),
        [locationRouterService, onClose, notificationsLocation],
    );

    const { onClickByNotificationType, titleByNotificationType } =
        useNotificationActions();

    return (
        <UnityLayout style={{ height: "50vh" }}>
            <UnityLayout.Header
                actionButtons={actionButtons}
                compact
                size={4}
                title={t("header.notifications.title")}
            />

            <UnityLayout.Content
                stretch={false}
                loading={isLoading}
                onScroll={onScroll}
            >
                <div className="pmHeaderNotifications-content">
                    {hasNotifications ? (
                        notifications.map(notification => {
                            return (
                                <HeaderNotificationsItem
                                    key={`notification-${notification.id}`}
                                    status={notification.severity}
                                    onClick={event => {
                                        event.stopPropagation();
                                        if (notification.readAt === null) {
                                            markAsRead([notification.id]);
                                        }
                                        onClose();
                                        return onClickByNotificationType(
                                            notification,
                                        )();
                                    }}
                                    title={titleByNotificationType(
                                        notification,
                                    )}
                                    message={messageByNotificationType(
                                        notification,
                                    )}
                                    updatedAt={notification.createdAt}
                                    isRead={notification.readAt !== null}
                                />
                            );
                        })
                    ) : (
                        <EmptyScreen />
                    )}
                </div>
            </UnityLayout.Content>

            {hasNotifications && (
                <UnityLayout.Footer
                    actionButtons={footerActionButtons}
                    compact
                />
            )}
        </UnityLayout>
    );
};

HeaderNotifications.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default HeaderNotifications;
