import { ALL_ENTITY_IDS } from "@/components/CreateJobTaskAlert/loadables";
import { Forms } from "@/components/DesignSystem";
import { noValueFn, orEmpty } from "@/components/DesignSystem/Forms/validators";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { isEmailValid } from "@/utils/validation";
import apply from "lodash/fp/apply";
import compose from "lodash/fp/compose";
import defaultTo from "lodash/fp/defaultTo";
import eq from "lodash/fp/eq";
import get from "lodash/fp/get";
import isEmpty from "lodash/fp/isEmpty";
import juxt from "lodash/fp/juxt";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import useWith from "lodash/fp/useWith";
import uniq from "lodash/uniq";
import { useCallback, useMemo, useRef } from "react";
import {
    DID_NOT_RUN_LABEL,
    DID_NOT_RUN_VALUE,
    MAX_RECIPIENTS_CHARS_LENGTH,
    REAL_TIME_MONITORING_LABEL,
    SCHEDULE_TYPE,
    TYPE,
} from "./constants";

// NAME
const useDoesNotExistValidator = ({
    projectId,
    entityIds,
    entityType,
    initialName,
    alertRuleId,
}) => {
    const { axiosService } = useDic();
    const doesNotExistValidator = useCallback(
        async value => {
            const useAll = entityIds?.includes(ALL_ENTITY_IDS);
            // Skip validation, when editing alert and name is not changed
            if (alertRuleId && initialName === value) return Forms.success();
            // Skip validation, when entityIds is empty
            if (isEmpty(entityIds)) return Forms.success();
            const aRes = await axiosService
                .post(`/api/projects/${projectId}/notification-rules/valid`, {
                    name: value,
                    entityIds: entityIds?.filter(id => id !== ALL_ENTITY_IDS),
                    useAll,
                    entityType,
                })
                .catch(e => console.error("async validation failed", e));
            const { data: valid = true } = aRes ?? {};
            return valid ? Forms.success() : Forms.error("Already exists");
        },
        [
            projectId,
            entityIds,
            entityType,
            axiosService,
            alertRuleId,
            initialName,
        ],
    );
    return doesNotExistValidator;
};

export const useNameValidator = ({
    projectId,
    entityIds,
    entityType,
    initialName,
    alertRuleId,
}) => {
    const doesNotExistValidator = useDoesNotExistValidator({
        projectId,
        entityIds,
        entityType,
        initialName,
        alertRuleId,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.notBlank(),
                Forms.pmValidators.createMinLengthValidation(5),
                debounceAsync(500, doesNotExistValidator),
            ]),
        [doesNotExistValidator],
    );
};

// ENTITY ID
export const useEntityIdValidator = ({ maybeOptions }) => {
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createMultiAllowedValuesValidator(
                    maybeOptions?.map(get("value")) ?? [],
                ),
            ]),
        [maybeOptions],
    );
};

// TARGET NAME
export const useTargetNameValidator = ({ maybeOptions }) => {
    return useMemo(
        () =>
            !maybeOptions
                ? undefined
                : Forms.pmValidators.createAllowedValuesValidator(
                      (maybeOptions.map(get("value")) ?? []).concat([
                          undefined,
                          null,
                      ]),
                      "Not found in possible values",
                  ),
        [maybeOptions],
    );
};

// TRIGGER VALUE
const selectedScheduleTypeTriggersValidator = scheduleType => async values => {
    // https://pricefx.atlassian.net/browse/PFIM-3588
    if (
        scheduleType === SCHEDULE_TYPE.REALTIME &&
        values?.includes(DID_NOT_RUN_VALUE)
    )
        return Forms.error(
            `State “${DID_NOT_RUN_LABEL}” can’t be selected for ${REAL_TIME_MONITORING_LABEL}`,
        );
    return Forms.success();
};

const defaultErrorFormatter = fieldNames =>
    `Required when none of these fields is filled in: ${fieldNames.join(", ")}`;

const useThisOrThoseRequired = ({
    formId,
    fieldNames: those,
    error = defaultErrorFormatter,
}) => {
    const thoseRef = useRef(those);
    if (JSON.stringify(thoseRef.current) !== JSON.stringify(those))
        throw new Error(
            `Must not change among renders! prev: ${JSON.stringify(
                thoseRef.current,
            )}, next: ${JSON.stringify(those)}`,
        );

    const values = thoseRef.current.map(name =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        Forms.useFieldValue({ formId, name }),
    );

    return useMemo(
        () => async (value, getBag) => {
            if (!noValueFn(value)) return Forms.success();

            const bag = await getBag();
            const someOtherHasValue = thoseRef.current.some(
                fieldName => !noValueFn(bag.values[fieldName]),
            );

            if (someOtherHasValue) return Forms.success();

            return Forms.error(
                typeof error === "function" ? error(thoseRef.current) : error,
            );
        },
        [error, ...values],
    );
};

export const useTriggerValueValidator = ({
    formId,
    type,
    scheduleType,
    triggerValueOptions,
}) => {
    const alertTriggerValues = useMemo(
        () => map(get("value"), triggerValueOptions),
        [triggerValueOptions],
    );

    const thisOrThoseRequired = useThisOrThoseRequired({
        formId,
        fieldNames: ["maxRunningTimeMinutes"],
        error: `Required when Max Running Time is not specified`,
    });

    return useMemo(() => {
        const commonValidators = [
            Forms.pmValidators.createMinLengthValidation(1, {
                messageKey: "general.validation.array.min-length",
            }),
            selectedScheduleTypeTriggersValidator(scheduleType),
            Forms.pmValidators.createMultiAllowedValuesValidator(
                alertTriggerValues,
                notAllowed => "Not allowed values: " + notAllowed.join(", "),
            ),
        ];
        return Forms.validators.failOnFirst([
            ...(type === TYPE.ROUTE_CHECK
                ? [
                      thisOrThoseRequired,
                      orEmpty(Forms.validators.failOnFirst(commonValidators)),
                  ]
                : [Forms.pmValidators.isRequired, ...commonValidators]),
        ]);
    }, [alertTriggerValues, thisOrThoseRequired, type, scheduleType]);
};

// MAX RUNNING TIME
export const useMaxRunningTimeMinutesValidator = ({ formId }) => {
    return useThisOrThoseRequired({
        formId,
        fieldNames: ["triggerValue"],
        error: `Required when State is not specified`,
    });
};

// RECIPIENTS
// eslint-disable-next-line react-hooks/rules-of-hooks
export const converge = useWith(compose, [apply, juxt]);

export const uniqueArrayValuesValidator = pipe(
    defaultTo([]),
    // prettier-ignore
    converge(eq, [
        get("length"),
        pipe(uniq, get("length")),
    ]),
    unique => (unique ? Forms.success() : Forms.error("Must be unique")),
);

export const validEmailsValidator =
    Forms.pmValidators.createEachArrayItemValidator(
        isEmailValid,
        "Email must be valid",
    );

export const useRecipientsValidator = ({ webHookValue }) => {
    const emailsRequiredWhenNoWebhookValidator = useMemo(
        () => async emails => {
            if (!emails?.length && !webHookValue)
                return Forms.error("Required when webhook not specified");

            if (emails?.join(",").length > MAX_RECIPIENTS_CHARS_LENGTH) {
                return Forms.error(
                    t("validation.alert-rule.recipients-too-long", {
                        max: MAX_RECIPIENTS_CHARS_LENGTH,
                    }),
                );
            }

            if (emails?.some(recipient => !isEmailValid(recipient))) {
                return Forms.error(
                    t("validation.alert-rule.valid-email-address"),
                );
            }

            return Forms.success();
        },
        [webHookValue],
    );

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                uniqueArrayValuesValidator,
                emailsRequiredWhenNoWebhookValidator,
                validEmailsValidator,
            ]),
        [emailsRequiredWhenNoWebhookValidator],
    );
};
