import { Alert, Button } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { MINUTE } from "@/components/Integrations/ImUpdate/constants";
import { useDelayedEffect } from "@/components/hooks/useDelayedEffect.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { isNewer } from "@/utils/versionUtils";
import React, { useEffect } from "react";
import "./UpdateChecker.style.less";

const RECHECK_DELAY = 20 * MINUTE * 1000;
export const UpdateChecker = () => {
    const updateAlert = useVisibility(false);
    const { axiosService } = useDic();

    const deployedFEVersionResource = useQueryLoadable(() => {
        return axiosService
            .get(`/version.txt?timestamp=${Date.now()}`, {
                headers: {
                    "Cache-Control": "no-store",
                },
                cache: {
                    maxAge: 0,
                },
            })
            .then(getData);
    }, [axiosService]);

    const deployedVersion = deployedFEVersionResource.loadable.valueMaybe();

    useDelayedEffect(deployedFEVersionResource.reload, RECHECK_DELAY, [
        updateAlert,
        deployedFEVersionResource.loadable,
    ]);

    useEffect(() => {
        if (deployedVersion) {
            if (isNewer(deployedVersion, process.env.VERSION)) {
                updateAlert.show();
            }
        }
    }, [deployedVersion, updateAlert]);

    return updateAlert.visible ? (
        <Alert
            data-test="update-checker"
            showIcon
            withMargin
            type="info"
            message={
                <>
                    {t("update-checker.message")}
                    {/* TODO migrate to Alert action in future UC */}
                    <Button
                        data-test="update-checker.reload"
                        type="primary"
                        size="small"
                        label={t("general.reload")}
                        onClick={() => window.location.reload(true)}
                    />
                </>
            }
            className="pmUpdateChecker"
        />
    ) : null;
};
