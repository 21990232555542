import { tap } from "@/components/Connections/loadables";
import { warningToMessage } from "@/components/DataUploads/Warning/DataUploadWarning.component";
import { useDataLoadWarningsQuery } from "@/components/DataUploads/Wizard/loadables";
import { Link } from "@/components/DesignSystem/Link";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { ENTITY_TYPE_CODES_FE } from "@/constants/SupportedTableImportTypes";
import { hasError, isLoading, useQueryLoadable } from "@/modules/loadable";
import { T, t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { defaultTo, get, map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getDataUploadsListTrackName } from "../../mixpanel/buttonNames";
import { useAlertEffect } from "../PageLayout/useAlertEffect.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { CopyModal } from "./CopyModal";
import { columns } from "./DataUploadsList.columns";
import QuickDataUploadModal from "./QuickDataUploadModal.component";
import { useDataUploadsQuery, useDeleteDataUploadMutation } from "./loadables";

const USER_ENTITY_TYPE = ENTITY_TYPE_CODES_FE["U"].value;
const preferencesType = preferencesTypes.DATA_UPLOADS_LIST_TABLE;

export const getEntityLabel = ({ entityType, entityName }) => {
    const composed = [entityType, entityName?.split?.("$")?.[0]]
        .filter(Boolean)
        .join(" / ");
    return composed;
};

export const DataUploadsList = ({ projectId, partitionId, visible = true }) => {
    const [errorMessage, setErrorMessage] = useState();
    const quickDataUploadModal = useVisibility(false);
    const copyUploadControls = useDetailDrawerState();
    const {
        locationRouterService,
        projectPartitionApiService,
        accountAppLocations: {
            partitionNewUploadLocation,
            partitionUploadHistoryLocation,
            partitionUploadEditLocation,
            partitionCredentialsLocation,
            partitionDataUploadSFTPManagementLocation,
        },
    } = useDic();
    const dataUploadsQuery = useDataUploadsQuery({ partitionId });
    const uploads = useMemo(
        () =>
            pipe(
                defaultTo([]),
                map(row => ({
                    ...row,
                    __composedEntity: getEntityLabel(row),
                })),
            )(dataUploadsQuery.loadable.valueMaybe()),
        [dataUploadsQuery],
    );

    const deleteDataUploadMutation = useDeleteDataUploadMutation({
        partitionId,
        afterSuccess: dataUploadsQuery.reload,
    });
    const dataLoadWarningsQuery = useDataLoadWarningsQuery({
        partitionId,
    });
    const [maybeInUseWarning] =
        dataLoadWarningsQuery.loadable.valueMaybe() || [];

    useAlertEffect(visible && !!maybeInUseWarning, () => ({
        type: "error",
        message: t("data-upload.warnings.error-message"),
        description: warningToMessage(maybeInUseWarning),
    }));

    useAlertEffect(visible && errorMessage, () => ({
        type: "error",
        message: t("partition.connection.unavailable"),
        description: (
            <T
                id="partition.connection.unavailable.description"
                values={{
                    message: errorMessage,
                    clickHereLink: (
                        <Link
                            onClick={() =>
                                locationRouterService.navigate(
                                    partitionCredentialsLocation,
                                )
                            }
                        >
                            <T id="partition.connection.unavailable.link" />
                        </Link>
                    ),
                }}
            />
        ),
    }));

    const partitionAvailabilityResource = useQueryLoadable(async () => {
        return projectPartitionApiService
            .testPartitionAvailability(projectId, partitionId)
            .catch(e => {
                setErrorMessage(getErrorMessage(e.response.data));
                throw e;
            });
    }, [partitionId, projectId, projectPartitionApiService]);

    const partitionInfoLoading =
        isLoading(dataLoadWarningsQuery) ||
        isLoading(partitionAvailabilityResource);
    const partitionInfoHasError =
        hasError(dataLoadWarningsQuery) ||
        hasError(partitionAvailabilityResource) ||
        !!maybeInUseWarning;

    useBreadcrumbButtons(
        () =>
            visible && [
                {
                    type: "primary",
                    label: "data-upload.action.create-data-upload",
                    loading: partitionInfoLoading,
                    disabled: partitionInfoHasError,
                    onClick: () =>
                        locationRouterService.navigate(
                            partitionNewUploadLocation,
                        ),
                    permissions: ["partition.data_upload.edit"],
                },
                {
                    type: "secondary",
                    label: "data-upload.action.quick-data-upload",
                    loading: partitionInfoLoading,
                    disabled: partitionInfoHasError,
                    onClick: quickDataUploadModal.show,
                    permissions: ["partition.data_upload.edit"],
                },
            ],
        [partitionInfoHasError, partitionInfoLoading, visible],
        "DataUploadsList",
    );

    const handleUploadHistory = useCallback(
        record => {
            locationRouterService.navigate(partitionUploadHistoryLocation, {
                uploadId: record.id,
            });
        },
        [locationRouterService, partitionUploadHistoryLocation],
    );

    const navigateToSFTPMangement = useCallback(
        record => {
            locationRouterService.navigate(
                partitionDataUploadSFTPManagementLocation,
                {
                    uploadId: record.id,
                },
            );
        },
        [locationRouterService, partitionDataUploadSFTPManagementLocation],
    );

    const handlePartitionUpload = useCallback(
        record => {
            !errorMessage &&
                locationRouterService.navigate(partitionUploadEditLocation, {
                    uploadId: record.id,
                });
        },
        [errorMessage, locationRouterService, partitionUploadEditLocation],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("data-uploads-list.delete.show-history"),
                        permission: [
                            "partition.data_upload.use",
                            "partition.data_upload.edit",
                        ],
                        onClick: handleUploadHistory,
                    },
                    {
                        title: t("data-uploads-list.delete.send-file"),
                        permission:
                            record.entityType === USER_ENTITY_TYPE
                                ? ["partition.data_upload.users_import"]
                                : [
                                      "partition.data_upload.use",
                                      "partition.data_upload.edit",
                                  ],
                        visible: !maybeInUseWarning,
                        onClick: handlePartitionUpload,
                    },
                    {
                        title: t("data-uploads-list.copy.title"),
                        permission:
                            record.entityType === USER_ENTITY_TYPE
                                ? ["partition.data_upload.users_import"]
                                : ["partition.data_upload.edit"],
                        onClick: copyUploadControls.show,
                        track: { name: getDataUploadsListTrackName("Copy") },
                    },
                    {
                        title: t("data-uploads-list.sftp-management.title"),
                        // permission:
                        //     record.entityType === USER_ENTITY_TYPE
                        //         ? ["partition.data_upload.users_import"]
                        //         : ["partition.data_upload.edit"],
                        onClick: navigateToSFTPMangement,
                        track: {
                            name: getDataUploadsListTrackName(
                                "SFTP User Management",
                            ),
                        },
                    },
                    {
                        title: t("data-uploads-list.delete.title"),
                        permission: ["partition.data_upload.edit"],
                        confirmMessage: t("data-uploads-list.delete.message", {
                            dataUploadName: record.name,
                        }),
                        onConfirm: deleteDataUploadMutation.mutate,
                        color: "red",
                        track: { name: getDataUploadsListTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            copyUploadControls.show,
            deleteDataUploadMutation.mutate,
            handlePartitionUpload,
            handleUploadHistory,
            maybeInUseWarning,
            navigateToSFTPMangement,
        ],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                dataSource={uploads}
                fixed
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                defaultSort={{
                    sortSpecifiers: [
                        { property: "updatedAt", direction: "descending" },
                    ],
                }}
                visible={visible}
            />
            <QuickDataUploadModal
                projectId={projectId}
                partitionId={partitionId}
                visible={quickDataUploadModal.visible}
                onSuccess={dataUploadsQuery.reload}
                onCancel={quickDataUploadModal.hide}
            />
            <CopyModal
                projectId={projectId}
                modalControls={copyUploadControls}
                afterSuccess={res => {
                    copyUploadControls.hide();
                    if (!errorMessage)
                        locationRouterService.navigate(
                            partitionUploadEditLocation,
                            {
                                uploadId: res.data[0].dataUploadId,
                                partitionId: res.data[0].partitionId,
                            },
                        );
                }}
                onError={pipe(
                    get(["response", "data"]),
                    getErrorMessage,
                    setErrorMessage,
                    tap(copyUploadControls.hide),
                )}
            />
        </>
    );
};

DataUploadsList.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
};
