import SFTPServerInfo from "@/components/DataUploads/SftpManagement/SFTPServerInfo.component";
import {
    useDeleteSftpUserMutation,
    useSftpServerUsersQuery,
} from "@/components/DataUploads/SftpManagement/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { SFTPServerUsersList } from "@/components/SFTPServers/SFTPServerUsers.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import React, { useCallback } from "react";

export const TABS = {
    SFTP_SERVERS: "SFTP_SERVERS",
    EMAIL_NOTIFICATIONS: "EMAIL_NOTIFICATIONS",
};

export function DataUploadSFTPManagementPage() {
    const { uploadId, SFTP_ENTITY_TYPE } = useAccountAppParams();

    const { locationRouterService, accountAppLocations } = useDic();

    const showDataUploads = useCallback(
        () =>
            locationRouterService.navigate(
                accountAppLocations.partitionUploadsLocation,
            ),
        [accountAppLocations.partitionUploadsLocation, locationRouterService],
    );
    useFullWidthTableLayout();
    useSetPageTitle("SFTP User Management");
    useSetBackButtonAction(showDataUploads);

    const sftpServerUsersQuery = useSftpServerUsersQuery({
        dataLoadId: uploadId,
        type: SFTP_ENTITY_TYPE,
    });
    const deleteSftpUserMutation = useDeleteSftpUserMutation({
        dataLoadId: uploadId,
        type: SFTP_ENTITY_TYPE,
        afterSuccess: sftpServerUsersQuery.reload,
    });

    const createOnEdit = useCallback(
        userId => () =>
            locationRouterService.navigate(
                accountAppLocations.partitionDataUploadSFTPManagementServerUserLocation,
                { userId },
            ),
        [
            locationRouterService,
            accountAppLocations.partitionDataUploadSFTPManagementServerUserLocation,
        ],
    );

    return (
        <>
            <SFTPServerInfo dataLoadId={uploadId} type={SFTP_ENTITY_TYPE} />
            <SFTPServerUsersList
                isLDAP
                createOnEdit={createOnEdit}
                sftpServerUsersQuery={sftpServerUsersQuery}
                deleteSftpUserMutation={deleteSftpUserMutation}
                restHeight={150}
            />
        </>
    );
}
