import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable, waitForValue } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { getErrorMessage } from "@/utils/state/error.utils";

export const useEmptyResource = response => {
    return useQueryLoadable(async () => response, []);
};

export const useTransportsEnvListQuery = (instanceId, canFetch) => {
    const { instanceService, messageService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? instanceService
                      .getListOfEnvironmentsToTransport(instanceId)
                      .then(getData)
                      .then(envs =>
                          envs.map(env => ({ label: env, value: env })),
                      )
                      .catch(e => {
                          messageService.error({
                              content: getErrorMessage(e.response.data),
                          });
                          throw e;
                      })
                : waitForValue(),
        [instanceService, canFetch, messageService, instanceId],
    );
};
