import {
    ALERT_TRIGGER_STATE_BY_TYPE,
    ENTITY_TYPE,
    SCHEDULE_TYPE,
    TYPE,
} from "@/components/CreateJobTaskAlert/constants";
import { CreateJobTaskAlertModal } from "@/components/CreateJobTaskAlert/CreateJobTaskAlert.modal";
import {
    getNotificationRuleUrl,
    useDeleteNotificationRuleMutation,
} from "@/components/CreateJobTaskAlert/loadables";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { ACCOUNT_ALERT_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { buildPageableUrl } from "@/utils/urlUtils";
import React, { useCallback, useState } from "react";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";

const preferencesType = preferencesTypes.NOTIFICATION_RULES_LIST_TABLE;

export const alertRulesColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.OPTION,
        label: "Type",
        name: "type",
        options: [
            { value: TYPE.PARTITION_JOB, label: TYPE.PARTITION_JOB },
            { value: TYPE.ROUTE_CHECK, label: TYPE.ROUTE_CHECK },
        ],
    },
    {
        type: fieldTypes.OPTION,
        label: t("alert-rules-list.header.entity-type"),
        name: "entityType",
        options: [
            { value: null, label: "All" },
            {
                value: ENTITY_TYPE.PARTITION,
                label: t("alert-rules-list.entity.partition"),
            },
            {
                value: ENTITY_TYPE.INTEGRATION_MANAGER_INSTANCE,
                label: t("alert-rules-list.entity.integration"),
            },
        ],
        render: (text, record) => {
            return ENTITY_TYPE.PARTITION === record.entityType
                ? t("alert-rules-list.entity.partition")
                : t("alert-rules-list.entity.integration");
        },
    },
    {
        type: fieldTypes.TEXT,
        label: "Partitions",
        name: "serialNumbers",
    },
    {
        type: fieldTypes.TEXT,
        label: "IM Instance",
        name: "instanceNames",
    },
    {
        type: fieldTypes.OPTION,
        label: "Notification Type",
        name: "scheduleType",
        options: [
            { value: SCHEDULE_TYPE.REALTIME, label: SCHEDULE_TYPE.REALTIME },
            { value: SCHEDULE_TYPE.SCHEDULED, label: SCHEDULE_TYPE.SCHEDULED },
        ],
    },
    {
        type: fieldTypes.TEXT,
        label: "Scheduled at",
        name: "scheduledAt",
    },
    {
        type: fieldTypes.TEXT,
        label: "Timezone",
        name: "timezone",
    },
    {
        type: fieldTypes.TEXT,
        label: "Lookback minutes",
        name: "lookBackMinutes",
    },
    {
        type: fieldTypes.TEXT,
        label: "Execution Period",
        name: "executionPeriod",
    },
    {
        type: fieldTypes.TEXT,
        label: "Target",
        name: "target",
    },
    {
        type: fieldTypes.TEXT,
        label: "Target Name",
        name: "targetName",
    },
    {
        type: fieldTypes.TEXT,
        label: "Target Type",
        name: "targetType",
    },
    {
        type: fieldTypes.TEXT,
        label: "Trigger Value",
        name: "triggerValue",
        // render: (_, record) =>
        //     translateTriggerValue(record?.triggerValue, record.type)
    },
    {
        type: fieldTypes.TEXT,
        label: "Entity Id",
        name: "entityIds",
        render: (entityIds, record) => {
            if (record.useAll && !entityIds?.length) return "ALL";
            else return entityIds;
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("alert-rules-list.header.updated-at"),
        name: "updatedAt",
        render: (text, record) => {
            return record.updatedBy ? (
                <FormattedDateTime>{record.updatedAt}</FormattedDateTime>
            ) : (
                "Never"
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.updated-by"),
        name: "updatedBy",
    },
    {
        type: fieldTypes.TEXT,
        label: "Recipients",
        name: "recipients",
    },
    {
        type: fieldTypes.TEXT,
        label: "Weboook",
        name: "webHook",
    },
];

const translateTriggerValue = (triggerValues, type) => {
    const states = ALERT_TRIGGER_STATE_BY_TYPE[type];
    return triggerValues?.map(value => states[value])?.join();
};

export const NotificationRulesPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { axiosService } = useDic();
    const { accountId } = useAccountAppParams();

    const [selected, setSelected] = useState();

    const [tableProps, { reload }] = useFetchPage(
        async (page, size, sort, filter) => {
            const url = buildPageableUrl(
                getNotificationRuleUrl(accountId, "table"),
                page,
                size,
                sort,
            );
            return axiosService.post(url, filter);
        },
        [accountId],
    );
    const deleteNotificationRuleMutation = useDeleteNotificationRuleMutation({
        projectId: accountId,
        afterSuccess: reload,
    });

    useBreadcrumbButton({
        label: "alert-rules-list.button.new",
        onClick: () => setSelected({}),
        permissions: ACCOUNT_ALERT_EDIT_PERMISSIONS,
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={ACCOUNT_ALERT_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: setSelected,
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "Notification Rule",
                        }),
                        confirmMessage: t("general.modal.delete.message", {
                            name: record.name,
                            type: "Notification Rule",
                        }),
                        onConfirm: deleteNotificationRuleMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteNotificationRuleMutation.mutate],
    );
    const title =
        {
            [TYPE.PARTITION_JOB]: t("alerts.modal-edit.title"),
            [TYPE.ROUTE_CHECK]: t("alerts.modal-edit.integration-title"),
        }[selected?.type] || t("alerts.modal-create.advanced-title");

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                columns={alertRulesColumns}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl={getNotificationRuleUrl(accountId, "export")}
                {...tableProps}
            />
            <CreateJobTaskAlertModal
                title={title}
                visible={!!selected}
                afterSave={useCurrentHandler(() => {
                    setSelected();
                    reload();
                })}
                onCancel={() => setSelected()}
                projectId={accountId}
                alertRuleId={selected?.id}
            />
        </>
    );
};
