import { Gap, Table, Text } from "@/components/DesignSystem";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { useLoadableTableProps } from "@/components/Integrations/ApplicationProperties/loadables";
import { useModificationsDetailedQuery } from "@/components/Integrations/components/TransportModal/loadables";
import { hasValue } from "@/modules/loadable";
import { t } from "@/translations";
import { get } from "lodash/fp";
import React, { useCallback, useEffect, useMemo } from "react";

const objectsColumns = [
    {
        name: "path",
        label: t("instances.transport.to.modal.select-objects.path"),
        render: text => {
            const parts = text?.split("/") ?? [];
            const name = parts[parts.length - 1];
            return <span title={text}>{name}</span>;
        },
    },
    { name: "type", label: "Type" },
];

export const SelectObjectsStep = ({
    instanceId,
    instanceName,
    nextStep,
    onCancel,
    setAlert,
    targetEnvironment,
    setTransportObjects,
    useFooterButtons,
}) => {
    const modificationsLoadable = useModificationsDetailedQuery({
        instanceId,
        targetEnvironment,
    }).loadable;

    const rowSelection = hooks.useRowSelection({});
    const { changeRowKeys } = rowSelection;
    useEffect(() => {
        if (!hasValue(modificationsLoadable)) return;

        const modifications = modificationsLoadable.valueMaybe();
        const selectedRowKeys = modifications.map(get("_id"));
        changeRowKeys(selectedRowKeys);
    }, [changeRowKeys, modificationsLoadable]);

    const submitDisabled =
        !hasValue(modificationsLoadable) ||
        !rowSelection.selectedRowKeys?.length;
    const submit = useCallback(
        data => {
            setTransportObjects(data);
            nextStep();
        },
        [nextStep, setTransportObjects],
    );
    const onSubmit = useCallback(() => {
        const selectedPaths = rowSelection.selectedRowKeys.map(_id => {
            const record = modificationsLoadable
                .valueMaybe()
                .find(mod => mod._id === _id);
            return record.path;
        });

        submit({ paths: selectedPaths });
    }, [modificationsLoadable, rowSelection.selectedRowKeys, submit]);

    useFooterButtons(
        useMemo(
            () => [
                {
                    label: t("general.continue"),
                    type: "primary",
                    onClick: onSubmit,
                    disabled: submitDisabled,
                },
                {
                    label: t("general.cancel"),
                    type: "text",
                    onClick: onCancel,
                },
            ],
            [onCancel, onSubmit, submitDisabled],
        ),
    );

    return (
        <>
            <Text>
                {t("instances.transport.to.modal.select-objects", {
                    instanceName,
                })}
            </Text>
            <Gap />
            <Table
                rowKey="_id"
                columns={objectsColumns}
                fixedHeight={325}
                rowSelection={rowSelection}
                pagination={false}
                padding={false}
                hasColumnResizing
                {...useLoadableTableProps(modificationsLoadable)}
                // allExpanded={{ isAllExpandedDefault: true }} // TODO: waiting for BE
            />
            <Gap />
        </>
    );
};
