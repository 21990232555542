import { UCLayoutWithSteps } from "@/components/DataUploads/Wizard/NewTable/components/UCLayoutWithSteps";
import { noop } from "lodash/fp";
import React, { useCallback, useState } from "react";

const GET_STEP_BUTTONS = ({ stepperProps, formId }) => [];

export const StepForm = ({
    title,
    initialValues = {},
    onSubmit = noop,
    onCancel,
    extraProps,
    steps,
    getStepButtons = GET_STEP_BUTTONS,
    initialStepIndex = 0,
    alignButtons,
    allValues: allValuesProp,
    setAllValues: setAllValuesProp,
}) => {
    const [_allValues, _setAllValues] = useState(initialValues);
    const [allValues, setAllValues] = setAllValuesProp
        ? [allValuesProp, setAllValuesProp]
        : [_allValues, _setAllValues];
    const mapStepProps = useCallback(
        stepperProps => ({
            stepperProps,
            extraProps,
            allValues,
            initialValues,
            onCancel,
            setAllValues,
            onSubmitStep: ({ values, args }) => {
                const [action] = args;
                const newValues = { ...allValues, ...values };
                setAllValues(newValues);
                if (typeof action === "function") {
                    return action({ values: newValues, args });
                } else if (stepperProps.isLastStep) {
                    return onSubmit({
                        values: newValues,
                        args,
                    });
                } else {
                    stepperProps.nextStep();
                }
            },
            getStepButtons,
        }),
        [
            allValues,
            extraProps,
            getStepButtons,
            initialValues,
            onCancel,
            onSubmit,
            setAllValues,
        ],
    );

    return (
        <UCLayoutWithSteps
            title={title}
            initialStepIndex={initialStepIndex}
            onClickBack={onCancel}
            mapStepProps={mapStepProps}
            steps={steps}
            alignButtons={alignButtons}
            stretch={false}
            isRemote
        />
    );
};
