import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import {
    useChangeEventWFStatusMutation,
    useDeleteEventWFMutation,
    useEventWorkflowsQuery,
} from "@/components/EventWorkflows/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getEventWFTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { constant } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

export const createColumns = ({ accountId } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.target"),
        name: "target",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.workflows.column.description"),
        name: "description",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
        ],
        label: t("event-wf.workflows.column.status"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeEventWFStatusMutation}
                    from={({ checked }) => ({
                        wfId: record.id,
                        enabled: checked,
                        accountId,
                    })}
                    enabledResponseProp="enabled"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                />
            );
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.workflows.column.lastRun"),
        name: "lastRun",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.workflows.column.createdAt"),
        name: "createdAt",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.notes"),
        name: "notes",
        width: 88,
        canFilter: false,
    },
];

const preferencesType = preferencesTypes.EVENT_LISTENER_WF_LIST_TABLE;

export const EventWorkflows = ({
    accountId,
    visible,
    onEdit,
    reloadToken,
    reloadAll,
}) => {
    const listQuery = useEventWorkflowsQuery({
        accountId,
        reloadToken,
    });
    const deleteMutation = useDeleteEventWFMutation({
        accountId,
        afterSuccess: reloadAll,
    });

    const dataSource = useMemo(
        () =>
            addLocalFilteredAlerts([
                {
                    prop: "notes",
                    getVisible: ({ unableToRun }) => unableToRun,
                    getType: constant("RED"),
                    getTooltip: constant(t("general.unable-to-run")),
                },
            ])(listQuery.loadable.valueMaybe() || []),
        [listQuery],
    );
    const loading = isLoading(listQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "event workflow",
                        }),
                        confirm: {
                            message: `${t("general.modal.delete.message", {
                                name: record.name,
                                type: "event workflow",
                            })}`,
                            onConfirm: record =>
                                deleteMutation.mutate({ wfId: record.id }),
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [deleteMutation, onEdit],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={createColumns({ accountId })}
            loading={loading}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            // exportUrl={`/api/accounts/${accountId}/event-workflows/export`}
        />
    );
};

EventWorkflows.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.string,
    reloadAll: PropTypes.func.isRequired,
};
