import {
    STEP_SOURCE_EVENT_LOGIC,
    listenerStepFormSteps,
} from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { StepForm } from "@/components/StepForm";
import { t } from "@/translations";
import { identity, isEmpty } from "lodash/fp";
import React, { useCallback, useMemo } from "react";

const sourceEventFrom = ({ filters, ...item }) => ({
    ...item,
    ...(filters
        ? {
              eventFilter: { filters },
          }
        : {}),
});
const sourceEventTo = ({ eventFilter, ...item }) => ({
    ...item,
    ...{ filters: eventFilter?.filters || [{ jsonPath: "", value: "" }] },
});

const obj = {};

export const ListenerStepForm = ({
    accountId,
    initialValues: _initialValues = obj,
    listeners,
    isFirstListener,
    isSingleSource,
    singleSource,
    eoTypesQuery,
    onSubmit,
    onCancel,
}) => {
    const isNew = isEmpty(_initialValues);
    const initialValues = useMemo(() => {
        return {
            ..._initialValues,
            actions: _initialValues?.stepTarget?.actions?.map(identity),
            sourceEvents:
                _initialValues?.stepTrigger?.sourceEvents?.map(sourceEventTo),
            combinationLogic: _initialValues?.stepTrigger?.stepSourceEventLogic,
            _stepTriggerTimeoutInMinutes:
                _initialValues?.stepTrigger?.timeoutInMinutes,
        };
    }, [_initialValues]);
    const handleSubmit = useCallback(
        ({
            values: {
                sourceEvents = [],
                actions = [],
                combinationLogic = STEP_SOURCE_EVENT_LOGIC.ONE_OF,
                timeoutInMinutes,
                _stepTriggerTimeoutInMinutes,
                ...listenerRest
            },
        }) => {
            const payload = {
                ...initialValues,
                ...listenerRest,
                timeoutInMinutes,
                stepTarget: {
                    actions,
                },
                stepTrigger: {
                    sourceEvents: sourceEvents.map(sourceEventFrom),
                    stepSourceEventLogic: combinationLogic,
                    timeoutInMinutes: _stepTriggerTimeoutInMinutes,
                },
            };
            onSubmit(payload);
        },
        [initialValues, onSubmit],
    );

    const extraProps = useMemoByDeepEquality({
        accountId,
        existingNames: listeners
            .map(({ name }) => name)
            .filter(name => name !== initialValues?.name),
        isFirstListener,
        isSingleSource,
        singleSource,
        eoTypesQuery,
    });
    const getStepButtons = useCallback(
        ({ stepperProps, formId }) => [
            {
                label: !stepperProps.isLastStep
                    ? t("general.continue")
                    : isNew
                    ? "Finish Listener"
                    : t("general.save"),
                type: "primary",
                formId,
            },
            {
                visible: !stepperProps.isFirstStep,
                label: t("general.back"),
                type: "secondary",
                onClick: stepperProps.prevStep,
            },
            {
                visible: !!onCancel,
                label: t("general.cancel"),
                type: "text",
                onClick: onCancel,
            },
        ],
        [isNew, onCancel],
    );

    return (
        <StepForm
            title={
                isNew
                    ? t("event-wf.form.add-listener")
                    : t("event-wf.form.edit-listener")
            }
            initialValues={initialValues}
            steps={listenerStepFormSteps}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            extraProps={extraProps}
            getStepButtons={getStepButtons}
            initialStepIndex={0}
            alignButtons="left"
        />
    );
};
