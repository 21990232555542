import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";
import {
    ButtonMenu,
    Forms,
    H4,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { SELECT_GET_VALUE } from "@/components/DesignSystem/Forms/fields";
import { EventFilterList } from "@/components/EventWorkflows/EventFilterList";
import {
    useEOTEntitiesOptionsQuery,
    useEOTEventOptionsQuery,
    useEOTypesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { defaultTo, find, get, pipe, prop, propEq, propOr } from "lodash/fp";
import React, { useMemo } from "react";

export const useLoadableOptionsValidatorMaybe = ({
    loadable,
    msg = "Value not found in options",
    isRequired,
    extraAllowed = isRequired ? [] : [undefined, null],
}) => {
    const maybeOptions = loadable.valueMaybe();
    const allowedValues = (maybeOptions?.map(get("value")) ?? []).concat(
        extraAllowed,
    );

    const maybeValidator = useMemo(
        () =>
            isLoading(loadable)
                ? undefined
                : Forms.pmValidators.createAllowedValuesValidator(
                      allowedValues,
                      msg,
                  ),
        [allowedValues, loadable, msg],
    );
    return maybeValidator;
};

export const getOptionLabelKey = name => `_${name}_label`;

export const getOptionLabelMaybe = (value, loadable) =>
    pipe(
        defaultTo([]),
        find(propEq("value", value)),
        prop("label"),
    )(loadable?.valueMaybe());

export const SOURCE_EVENT_FILTER_PROP = {
    jsonPath: "jsonPath",
    value: "value",
};

export const SourceFields = ({
    eoTypesQuery,
    eotSourceEntitiesQuery,
    initialValues = {},
    readOnly,
    onChange,
}) => {
    const loadableSourceTypeValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eoTypesQuery.loadable,
    });

    const loadableSourceIdValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eotSourceEntitiesQuery.loadable,
    });

    if (readOnly) {
        return (
            <>
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        initialValues[SourceFields.fieldNames.sourceType]
                    }
                    name="sourceType"
                    label={t("event-wf.listener-form.sourceType.label")}
                    {...getLoadableSelectProps(eoTypesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        initialValues[SourceFields.fieldNames.sourceId]
                    }
                    name="sourceId"
                    label={t("event-wf.listener-form.sourceId.label")}
                    {...getLoadableSelectProps(eotSourceEntitiesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
            </>
        );
    }

    return (
        <>
            <Forms.Fields.Select
                name={SourceFields.fieldNames.sourceType}
                initialValue={initialValues[SourceFields.fieldNames.sourceType]}
                onChange={onChange}
                label={t("event-wf.listener-form.sourceType.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    loadableSourceTypeValidator,
                ])}
                {...getLoadableSelectProps(eoTypesQuery.loadable)}
            />
            <Forms.Fields.Select
                name={SourceFields.fieldNames.sourceId}
                initialValue={initialValues[SourceFields.fieldNames.sourceId]}
                onChange={onChange}
                label={t("event-wf.listener-form.sourceId.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    loadableSourceIdValidator,
                ])}
                {...getLoadableSelectProps(eotSourceEntitiesQuery.loadable)}
                {...idleLoadableProps(
                    eotSourceEntitiesQuery.loadable,
                    "Please, select type",
                )}
            />
        </>
    );
};

SourceFields.fieldNames = {
    sourceType: "sourceType",
    sourceId: "sourceId",
};

SourceFields.useSourceType = ({ formId } = {}) => {
    return Forms.useFieldValue({
        formId,
        name: SourceFields.fieldNames.sourceType,
    });
};
SourceFields.useSourceId = ({ formId } = {}) => {
    return Forms.useFieldValue({
        formId,
        name: SourceFields.fieldNames.sourceId,
    });
};

export const SourceEventModal = ({
    visible,
    onSubmit,
    onClose,
    isEdit,
    initialValues,
    modalExtraProps: {
        accountId,
        eoTypesQuery,
        isSingleSource = false,
        singleSource,
    },
}) => {
    const { formId, Form, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            const valuesWithNames = {
                ...values,
                [getOptionLabelKey("sourceId")]: getOptionLabelMaybe(
                    values.sourceId,
                    eotSourceEntitiesQuery.loadable,
                ),
            };
            return onSubmit(valuesWithNames);
        },
    });
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    const sourceType = SourceFields.useSourceType({ formId });
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: sourceType,
        canFetch: !!sourceType,
    });

    const eotSourceEventsQuery = useEOTEventOptionsQuery({
        accountId,
        eoType: sourceType,
        canFetch: !!sourceType,
    });
    const loadableSourceEventTypeValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eotSourceEventsQuery.loadable,
    });

    const title = t(
        isEdit
            ? "event-wf.listener-form.sourceEvents.modal.title.edit"
            : "event-wf.listener-form.sourceEvents.modal.title.add",
    );
    const submitLabel = t(isEdit ? "general.save" : "general.add");

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={title} />
                <UnityLayout.Content padding={[false, true]}>
                    <Form disableBanner>
                        <H4>
                            {t("event-wf.listener-form.sourceEventParameters")}
                        </H4>

                        <SourceFields
                            eoTypesQuery={eoTypesQuery}
                            eotSourceEntitiesQuery={eotSourceEntitiesQuery}
                            readOnly={isSingleSource}
                            initialValues={singleSource}
                        />
                        <Forms.Fields.Select
                            name="sourceEventType"
                            label={t(
                                "event-wf.listener-form.sourceEventType.label",
                            )}
                            inputWidth="max"
                            width="max"
                            required
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                loadableSourceEventTypeValidator,
                            ])}
                            {...getLoadableSelectProps(
                                eotSourceEventsQuery.loadable,
                            )}
                            {...idleLoadableProps(
                                eotSourceEventsQuery.loadable,
                                "Please, select type",
                            )}
                        />
                        <H4>{t("event-wf.listener-form.filter")}</H4>
                        <EventFilterList
                            formId={formId}
                            name="filters"
                            initialValue={initialValues?.filters}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: submitLabel,
                                    type: "primary",
                                    htmlType: "submit",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

SourceEventModal.propTypes = {};
