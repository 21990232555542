import {
    useSftpServerQuery,
    useSftpServerUserMutation,
    useSftpServerUserQuery,
    useSftpServerUsersQuery,
} from "@/components/DataUploads/SftpManagement/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useParseSftpKeyMutation } from "@/components/SFTPServers/loadables";
import SFTPUserForm from "@/components/SFTPServers/SFTPUserForm.component";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { get, map } from "lodash/fp";
import React, { useCallback } from "react";

export const SFTPDataLoadUserPage = () => {
    const { uploadId, userId, SFTP_ENTITY_TYPE } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataUploadSFTPManagementLocation },
    } = useDic();
    const goBack = useCallback(
        () =>
            locationRouterService.navigate(
                partitionDataUploadSFTPManagementLocation,
            ),
        [locationRouterService, partitionDataUploadSFTPManagementLocation],
    );

    useSetBackButtonAction(goBack);
    const sftpServerUsernamesLoadable = useMapLoadableMemoized(
        useSftpServerUsersQuery({
            dataLoadId: uploadId,
            type: SFTP_ENTITY_TYPE,
        }).loadable,
        map(get("username")),
    );

    const isNew = userId === "new";

    const sftpServerQuery = useSftpServerQuery({
        dataLoadId: uploadId,
        type: SFTP_ENTITY_TYPE,
    });
    const sftpServerUserQuery = useSftpServerUserQuery({
        dataLoadId: uploadId,
        type: SFTP_ENTITY_TYPE,
        userId,
        isNew,
    });
    const initialValues = sftpServerUserQuery.loadable.valueMaybe();
    const sftpServerUserMutation = useSftpServerUserMutation({
        dataLoadId: uploadId,
        type: SFTP_ENTITY_TYPE,
        username: initialValues?.username,
        afterSuccess: goBack,
    });

    const parseSftpKeyMutation = useParseSftpKeyMutation();

    const { prefix: maybePrefix } = sftpServerQuery.loadable.valueMaybe() ?? {};
    return (
        <SFTPUserForm
            withEmailNotifications
            sftpServerQuery={sftpServerQuery}
            sftpServerUserQuery={sftpServerUserQuery}
            sftpServerUserMutation={sftpServerUserMutation}
            sftpServerUsernamesLoadable={sftpServerUsernamesLoadable}
            parseSftpKeyMutation={parseSftpKeyMutation}
            isNew={isNew}
            maybePrefix={maybePrefix}
            isLDAP
            initialValues={initialValues}
            goBack={goBack}
        />
    );
};

SFTPDataLoadUserPage.propTypes = {};
