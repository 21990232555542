import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    REPEAT_OPTIONS,
    getTimingName,
} from "@/components/EventSchedulers/TimingFields";
import {
    useChangeEventSchedulerStatusMutation,
    useDeleteEventSchedulerMutation,
    useEventSchedulersQuery,
} from "@/components/EventSchedulers/loadables";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getEventSchedulerTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { flatten } from "flat";
import { always, map, pipe } from "lodash/fp";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useReducer } from "react";

const addAlert = (
    row,
    {
        prop,
        getVisible = always(false),
        getType = always("YELLOW"),
        getTooltip = always(undefined),
    } = {},
) => {
    if (prop && getVisible(row))
        return {
            ...row,
            alerts: {
                ...(row.alerts || {}),
                [prop]: getTooltip(row),
                [`${prop}_ALERT_TYPE`]: getType(row),
            },
        };
    else return row;
};

const addAlerts =
    (alertDefs = []) =>
    row =>
        alertDefs.reduce(addAlert, row);

export const addLocalFilteredAlerts = alertDefs => map(addAlerts(alertDefs));

export const createColumns = ({ accountId } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.destinationName"),
        name: "destinationName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.destinationTargetLabel"),
        name: "destinationTargetLabel",
        // name: ActionFields.fieldNames.destinationTargetId,
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
        ],
        label: t("event-scheduler.list.columns.enabled"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeEventSchedulerStatusMutation}
                    from={({ checked }) => ({
                        accountId,
                        schedulerId: record.id,
                        enabled: checked,
                    })}
                    enabledResponseProp="enabled"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                />
            );
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.startTime"),
        name: getTimingName("startTime"),
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.OPTION,
        options: REPEAT_OPTIONS,
        label: t("event-scheduler.list.columns.type"),
        name: getTimingName("type"),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.endTime"),
        name: getTimingName("endTime"),
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.lastExecution"),
        name: "lastExecution",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.nextExecution"),
        name: "nextExecution",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
];

const preferencesType = preferencesTypes.EVENT_SCHEDULER_LIST_TABLE;
const zonedToMaybeUtcISO = (date, timezone) =>
    date ? moment.tz(date, timezone).toISOString() : null;

export const EventSchedulersPage = () => {
    const { accountId } = useAccountAppParams();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);
    useHideBackButton();

    const {
        locationRouterService,
        accountAppLocations: {
            eventSchedulerNewLocation,
            eventSchedulerEditLocation,
        },
    } = useDic();
    useBreadcrumbButton(
        {
            label: "location.accountApp.eventOrchestration.eventSchedulers.create",
            onClick: () =>
                locationRouterService.navigate(eventSchedulerNewLocation),
            track: { name: getTrackName("New") },
        },
        [],
    );
    const onEdit = useCallback(
        record =>
            locationRouterService.navigate(eventSchedulerEditLocation, {
                schedulerId: record?.id,
            }),
        [eventSchedulerEditLocation, locationRouterService],
    );

    const listQuery = useEventSchedulersQuery({
        accountId,
        reloadToken,
    });
    const deleteMutation = useDeleteEventSchedulerMutation({
        accountId,
        afterSuccess: reloadAll,
    });
    const dataSource = useMemo(
        () =>
            pipe(
                map(item => {
                    const flat = flatten(item, { maxDepth: 2 });
                    return {
                        ...flat,
                        [getTimingName("startTime")]: zonedToMaybeUtcISO(
                            flat[getTimingName("startTime")] ||
                                flat[getTimingName("eventTime")],
                            flat[getTimingName("eventTimeZone")],
                        ),
                        [getTimingName("endTime")]: zonedToMaybeUtcISO(
                            flat[getTimingName("endTime")],
                            flat[getTimingName("eventTimeZone")],
                        ),
                        nextExecution: zonedToMaybeUtcISO(
                            flat.nextExecution,
                            flat[getTimingName("eventTimeZone")],
                        ),
                    };
                }),
                addLocalFilteredAlerts([
                    {
                        prop: "destinationName",
                        getVisible: ({ destinationExists }) =>
                            !destinationExists,
                        getType: always("YELLOW"),
                        getTooltip: ({ destinationName }) =>
                            t("general.does-not-exist", {
                                item: destinationName,
                            }),
                    },
                ]),
            )(listQuery.loadable.valueMaybe() || []),
        [listQuery.loadable],
    );
    const loading = isLoading(listQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "Scheduler",
                        }),
                        confirm: {
                            message: `${t("general.modal.delete.message", {
                                name: record.name,
                                type: "Scheduler",
                            })}`,
                            onConfirm: record =>
                                deleteMutation.mutate({
                                    schedulerId: record.id,
                                }),
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [deleteMutation, onEdit],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={createColumns({
                accountId,
            })}
            loading={loading}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};
