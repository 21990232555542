import { Forms, H2, P } from "@/components/DesignSystem";
import React, { useMemo } from "react";

const FormStep = ({ Component, ...props }) => {
    const { stepperProps, allValues, onSubmitStep, getStepButtons } = props;
    const form = Forms.useForm({
        initialValues: allValues,
        onSubmit: onSubmitStep,
    });
    const { formId, Form, submit, getBag } = form;
    const [bagMaybe, refreshBag] = Forms.pmHooks.useBagMaybe({ getBag });

    const buttons = useMemo(
        () =>
            getStepButtons({
                stepperProps,
                formId,
                submit,
                isValid: bagMaybe?.validation?.isValid,
            }),
        [
            bagMaybe?.validation?.isValid,
            formId,
            getStepButtons,
            stepperProps,
            submit,
        ],
    );
    stepperProps.useFooterButtons(buttons);
    const {
        title,
        heading = title,
        HeadingComponent = H2,
        perex,
    } = stepperProps.currentStep;

    return (
        <Form disableBanner>
            {heading && <HeadingComponent>{heading}</HeadingComponent>}
            {perex && <P>{perex}</P>}
            <Component form={form} revalidate={refreshBag} {...props} />
        </Form>
    );
};

// formId needs to be accessible in each step Component
export const withForm = Component => props => {
    return <FormStep {...props} Component={Component} />;
};
