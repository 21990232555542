import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { UserContext } from "@/security/UserContext";
import { isEmpty } from "lodash/fp";
import { useContext } from "react";

export function useFeatureFlag(featureFlag) {
    const { basicInfo } = useContext(UserContext);
    const { accountId } = useAccountAppParams();

    const enabledFeatureFlag = basicInfo?.enabledFeatureFlags[featureFlag];

    return accountId && !isEmpty(enabledFeatureFlag)
        ? enabledFeatureFlag?.includes(accountId)
        : !!enabledFeatureFlag;
}
