import { useDic } from "@/components/Dic/useDic.hook";
import { useRouteAccountInstances } from "@/mixpanel/hooks/useRouteAccountInstances.hook";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import { hasValue } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import { SecurityContext } from "@/security/authorization";
import {
    getDefaultIntegrationLocation,
    getDefaultPartitionLocation,
} from "@/utils/location/defaultLocation.utils";
import head from "lodash/head";
import { useContext, useEffect } from "react";
import { routeParamsService } from "../../../apps/accountApp/services/routeParams.service";

export function useAccountAppParams() {
    const stringParams = useRouteParams();
    const params = routeParamsService.cast(stringParams);
    const secureContext = useContext(SecurityContext);
    const { locationRouterService, accountAppLocations } = useDic();
    const { accountPartitionsLoadable } = useRouteAccountPartitions();
    const { accountInstancesLoadable } = useRouteAccountInstances();

    // Redirects when partitionId or instanceId not found
    useEffect(() => {
        if (
            params.accountId &&
            params.partitionId &&
            hasValue(accountPartitionsLoadable)
        ) {
            const accountPartitionsIds = accountPartitionsLoadable
                .valueMaybe()
                .map(partition => partition.id);
            const firstAvailablePartition = head(accountPartitionsIds);
            if (
                firstAvailablePartition &&
                !accountPartitionsIds.includes(params.partitionId)
            ) {
                locationRouterService.navigate(
                    getDefaultPartitionLocation(
                        firstAvailablePartition,
                        secureContext,
                        accountAppLocations,
                    ).location,
                    {
                        partitionId: firstAvailablePartition,
                    },
                );
            }
        }
        if (
            params.accountId &&
            params.instanceId &&
            hasValue(accountInstancesLoadable)
        ) {
            const accountInstancesIds = accountInstancesLoadable
                .valueMaybe()
                .map(instance => instance.id);
            const firstAvailableInstance = head(accountInstancesIds);
            const shouldRedirect =
                firstAvailableInstance &&
                !accountInstancesIds.includes(params.instanceId);

            if (shouldRedirect) {
                locationRouterService.navigate(
                    getDefaultIntegrationLocation(
                        firstAvailableInstance,
                        secureContext,
                        accountAppLocations,
                    ).location,
                    {
                        instanceId: firstAvailableInstance,
                    },
                );
            }
        }
    }, [
        accountAppLocations,
        accountInstancesLoadable,
        accountPartitionsLoadable,
        locationRouterService,
        params.accountId,
        params.instanceId,
        params.partitionId,
        secureContext,
    ]);

    return params;
}
