import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    responseErrorMessage,
    useMutationLoadable,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { noop, tap } from "lodash/fp";
import qs from "qs";

export const useDataDownloadsQuery = ({ projectId, partitionId }) => {
    const { axiosService } = useDic();
    const queryStr = qs.stringify({ partitionId }, { addQueryPrefix: true });
    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/data-downloads${queryStr}`).then(getData),
        // .catch(e => [
        //     {
        //         name: "FE MOCK",
        //         entityType: "CX",
        //         entityName: "CustEXT",
        //         numberOfSftpUsers: 0,
        //         createdAt: "2022-06-17T06:39:27Z",
        //         createdBy: "jaroslava.novotna@pricefx.com",
        //         downloadedAt: null,

        //         id: 4552,
        //     },
        // ]),
        [axiosService, queryStr],
    );
};

export const useDeleteDataDownloadMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(`/api/data-downloads/${id}`)
                .then(tap(currentAfterSuccess)),
        [currentAfterSuccess, axiosService],
    );
};

export const useCreateDownloadMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    /* {
        "partitionId": 0,
        "name": "string",
        "entityType": "string",
        "entityName": "string",
        "filter": "string",
        "targetId": 0
    } */
    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/data-downloads`, { partitionId, ...payload })
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, partitionId],
    );
};

// https://qa.platform.pricefx.com/swagger-ui/index.html#/data-download-target-controller
export const useDownloadQuery = ({ accountId, partitionId, downloadId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/data-downloads/${downloadId}`).then(getData),
        [axiosService, downloadId],
    );
};
export const useAvailableFilesQuery = ({ downloadId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/data-downloads/${downloadId}/available-files`)
                .then(getData),
        [axiosService, downloadId],
    );
};

export const useEditDownloadMutation = ({
    accountId,
    partitionId,
    downloadId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    /* {
        "name": "string",
        "entityType": "string",
        "entityName": "string",
        "filter": "string",
        "targetId": 0
    } */
    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/data-downloads/${downloadId}`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, downloadId],
    );
};

export const useTriggerDownloadMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
} = {}) => {
    const { axiosService, messageService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const success = useCurrentHandler(() =>
        messageService.info({
            content: `Download in progress. Once finished, you will be notified in Notification Center. This may take several minutes. You may continue with your work.`,
        }),
    );
    const error = useCurrentHandler(e => {
        messageService.error({ content: responseErrorMessage(e) });
        throw e;
    });

    return useMutationLoadable(
        async ({ id }) =>
            axiosService
                .post(`/api/data-downloads/${id}/trigger`)
                .then(getData)
                .then(tap(success))
                .then(tap(afterSuccessCurrent))
                .catch(error),
        [afterSuccessCurrent, axiosService, error, success],
    );
};

export const useDownloadTargetsQuery = ({ partitionId }) => {
    const { axiosService } = useDic();
    const queryStr = qs.stringify(
        {
            partitionId,
            // name: "",
        },
        { addQueryPrefix: true },
    );
    /* [ {
    "id": 0,
    "name": "string",
    "type": "SFTP",
    "definition": {
      "type": "string",
      "host": "string",
      "port": 0,
      "username": "string",
      "password": "string"
    } } ] */
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/data-download-targets${queryStr}`)
                .then(getData),
        [axiosService, queryStr],
    );
};

export const useCreateDownloadTargetMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/data-download-targets`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
    );
};

export const useEditDownloadTargetMutation = ({
    accountId,
    partitionId,
    targetId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/data-download-targets/${targetId}`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, targetId],
    );
};

export const useDeleteDownloadTargetMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(`/api/data-download-targets/${id}`)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
    );
};

export const useDownloadTargetQuery = ({ partitionId, targetId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/data-download-targets/${targetId}`)
                .then(getData),
        [axiosService, targetId],
    );
};
