import { loadableFromMaybeValue } from "@/modules/loadable";
import { useMemo } from "react";
import { mapperMappingService } from "../../../../services/mapperMapping.service";
import { addComputableMapperProperties } from "../../form/mapper.utils";
import { useValidatedMapperState } from "./useValidatedMapperState.hook";

export const useMapperStateWithInitialization = ({
    entityType,
    entityName,
    initMapper,
    businessKey,
    inputOptionsWithTypes,
    outputOptionsLoadable,
    tableData,
}) => {
    const maybeMapperWithDefault = useMemo(() => {
        if (outputOptionsLoadable.state !== "hasValue") return undefined;

        const outputOptions = outputOptionsLoadable.contents;
        const initMapperWithDefault = initMapper
            ? // mapper from somewhere (api, cache, ...)
              addComputableMapperProperties(
                  initMapper,
                  outputOptions,
                  tableData,
              )
            : // Create default mapper
              mapperMappingService.mapTableDataToMetadata(
                  tableData,
                  outputOptions,
              );

        return initMapperWithDefault;
    }, [outputOptionsLoadable, initMapper, tableData]);

    const { mapper, setMapper, errors } = useValidatedMapperState({
        entityType,
        entityName,
        businessKey,
        initMapper: maybeMapperWithDefault,
        inputOptionsMaybe: inputOptionsWithTypes,
        outputOptionsMaybe: outputOptionsLoadable.valueMaybe(),
        tableData,
    });

    const loadableMapper = useMemo(
        () => loadableFromMaybeValue(mapper),
        [mapper],
    );

    return { mapper, setMapper, errors, loadableMapper };
};
