import {
    FooterButtonsContext,
    FooterButtonsProvider,
    useFooterButtons,
} from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
import {
    Alert,
    ButtonMenu,
    UnityLayout,
    UnitySteps,
} from "@/components/DesignSystem";
import RemoteLayout from "@/components/PageLayout/RemoteLayout";
import { identity, map, pick } from "lodash/fp";
import React, {
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";

const useSteps = ({ steps, initialStepIndex = 0 }) => {
    const stepsRef = useRef(steps);
    stepsRef.current = steps;
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
    const currentStep = steps[currentStepIndex];
    const nextStep = useCallback(
        () =>
            setCurrentStepIndex(index =>
                Math.min(index + 1, stepsRef.current.length - 1),
            ),
        [],
    );
    const prevStep = useCallback(
        () => setCurrentStepIndex(index => Math.max(index - 1, 0)),
        [],
    );

    return useMemo(
        () => ({
            steps,
            currentStep,
            currentStepIndex,
            nextStep,
            prevStep,
            isFirstStep: currentStepIndex === 0,
            isLastStep: currentStepIndex === steps.length - 1,
            setCurrentStepIndex,
        }),
        [currentStep, currentStepIndex, nextStep, prevStep, steps],
    );
};

const UCLayoutWithStepsInner = ({
    stepperProps,
    alert,
    title,
    onClickBack,
    mapStepProps: rootMapStepProps,
    alignButtons = "right",
    stretch,
    isRemote = false,
}) => {
    const {
        Component,
        mapStepProps = rootMapStepProps || identity,
        padding = [true, true],
    } = stepperProps.currentStep;
    const headerSteps = useMemo(
        // TODO: more properties?
        () => map(pick(["title"]), stepperProps.steps),
        [stepperProps.steps],
    );
    const { buttons } = useContext(FooterButtonsContext);
    const WrappedLayout = isRemote ? RemoteLayout : React.Fragment;
    const stepProps = useMemo(
        () =>
            mapStepProps({
                ...stepperProps,
                useFooterButtons,
            }),
        [mapStepProps, stepperProps],
    );

    return (
        <WrappedLayout>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={title}
                    alert={alert && <Alert {...alert} />}
                    onClickBack={onClickBack}
                    steps={
                        <UnitySteps
                            current={stepperProps.currentStepIndex}
                            steps={headerSteps}
                        />
                    }
                />

                <UnityLayout.Content padding={padding} stretch={stretch}>
                    <Component {...stepProps} />
                </UnityLayout.Content>
                {buttons?.length && (
                    <UnityLayout.Footer
                        {...(alignButtons === "left"
                            ? {
                                  actionButtonsLeft: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              }
                            : {
                                  actionButtons: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              })}
                    />
                )}
            </UnityLayout>
        </WrappedLayout>
    );
};

export const UCLayoutWithSteps = ({ steps, initialStepIndex, ...props }) => {
    const stepperProps = useSteps({ steps, initialStepIndex });

    return (
        <FooterButtonsProvider>
            <UCLayoutWithStepsInner stepperProps={stepperProps} {...props} />
        </FooterButtonsProvider>
    );
};

UCLayoutWithSteps.useSteps = useSteps;
