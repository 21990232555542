import { logger } from "@/modules/logger";
import { messageService } from "@/modules/Message/message.service";
import { createRouter, LocationRouterService } from "@/modules/router";
import { accountActivationService } from "@/services/accountActivation.service";
import { applicationPropertiesService } from "@/services/applicationProperties.service";
import { capacitySchedulingService } from "@/services/capacitySchedulingService";
import { classService } from "@/services/class.service";
import { clusterService } from "@/services/clusterService";
import { connectionsService } from "@/services/connections.service";
import { convertersService } from "@/services/converters.service";
import { createInstanceService } from "@/services/create-instance.service";
import { dataLoadSftpService } from "@/services/dataLoadSftp.service";
import { deploymentService } from "@/services/deployment.service";
import { elkQueryService } from "@/services/elkQuery.service";
import { favoritesService } from "@/services/favorites.service";
import { filtersService } from "@/services/filters.service";
import { instancesServices } from "@/services/instance.service";
import { integrationPropertiesService } from "@/services/integration.property.service";
import { integrationManagerUpdateService } from "@/services/integrationManagerUpdate.service";
import { imVersionsService } from "@/services/integrationManagerVersions.service";
import { marketplaceItemsService } from "@/services/marketplaceItems.service";
import { metadataService } from "@/services/metadata.service";
import { nodeService } from "@/services/node.service";
import { notificationsService } from "@/services/notifications.service";
import { partitionService } from "@/services/partition.service";
import { createPartitionRequestService } from "@/services/partitionRequest.service";
import { passwordsService } from "@/services/passwords.service";
import { registryService } from "@/services/registry.service";
import { remoteLoginService } from "@/services/remoteLogin.service";
import { resourcesService } from "@/services/resources.service";
import { routesService } from "@/services/routes.service";
import { serverService } from "@/services/server.service";
import sftpService from "@/services/sftp.service";
import ssoService from "@/services/sso.service";
import { taskService } from "@/services/task.service";
import { templatesService } from "@/services/templates.service";
import { templateAdminService } from "@/services/templatesAdmin.service";
import { templatesReplacementService } from "@/services/templatesReplacement.service";
import { tokensService } from "@/services/token.service";
import { UUIDService } from "@/services/UUIDService.class";
import workflowService from "@/services/workflow.service";
import { asClass, asFunction, asValue } from "awilix";
import { accountsService } from "../apps/accountApp/services/accounts.service";
import { createAdminApiService } from "../apps/accountApp/services/adminApi.service";
import { userService } from "../apps/accountApp/services/user.service";
import { groupService } from "../apps/administrationApp/services/group.service";
import { createAxiosClient } from "../axios";
import { AxiosHttpClient } from "../axios/AxiosHttpClient.class";
import { routeDiagramService } from "../components/Camel/RouteDiagram/routeDiagram.service";
import * as locations from "../locations";
import { mixpanelService } from "../mixpanel/mixpanel.service";
import { createStore } from "../redux/factories";
import { HttpService } from "../utils/http/HttpService.class";
import { packageService } from "../views/Package/package.service";
import { registerApps } from "./registerApps.dic";
import { registerRematchModels } from "./rematchModels.dic";

export function registerServices(dic, { authenticationService }) {
    logger.debug({
        logGroupKey: ["SCAFFOLD", "registerServices"],
        color: "deepskyblue",
    });
    dic.register({
        authenticationService: asValue(authenticationService),
        accountActivationService: asValue(accountActivationService),
        elkQueryService: asValue(elkQueryService),
        locations: asValue(locations.locationsMap),
        routerService: asFunction(createRouter).singleton(),
        mixpanelService: asValue(mixpanelService),
        accountsService: asValue(accountsService),
        clusterService: asValue(clusterService),
        capacitySchedulingService: asValue(capacitySchedulingService),
        dataLoadSftpService: asValue(dataLoadSftpService),
        axiosService: asFunction(createAxiosClient).singleton(),
        locationRouterService: asClass(LocationRouterService).singleton(),
        notificationsService: asValue(notificationsService),
        messageService: asFunction(messageService).singleton(),
        store: asFunction(createStore).singleton(),
        packageService: asValue(packageService),
        window: asValue(window),
        localStorage: asValue(localStorage),
        sessionStorage: asValue(sessionStorage),
        httpClient: asClass(AxiosHttpClient).singleton(),
        httpService: asClass(HttpService).singleton(),
        registryService: asValue(registryService),
        routeDiagramService: asValue(routeDiagramService),
        uuidService: asClass(UUIDService).singleton(),
        adminApiService: asFunction(createAdminApiService).singleton(),
        userService: asValue(userService),
        groupService: asValue(groupService),
        remoteLoginService: asValue(remoteLoginService),
        convertersService: asValue(convertersService),
        metadataService: asValue(metadataService),
        connectionsService: asValue(connectionsService),
        classService: asValue(classService),
        sftpService: asValue(sftpService),
        routesService: asValue(routesService),
        templateService: asValue(templatesService),
        templatesReplacementService: asValue(templatesReplacementService),
        templateAdminService: asValue(templateAdminService),
        tokensService: asValue(tokensService),
        marketplaceItemsService: asValue(marketplaceItemsService),
        workflowService: asValue(workflowService),
        favoritesService: asValue(favoritesService),
        taskService: asValue(taskService),
        applicationPropertiesService: asValue(applicationPropertiesService),
        partitionService: asValue(partitionService),
        partitionRequestService: asFunction(createPartitionRequestService),
        baseURL: asValue(process.env.API_URL || ""),
        filtersService: asValue(filtersService),
        deploymentService: asValue(deploymentService),
        createInstanceService: asValue(createInstanceService), // TODO: rename
        instanceService: asValue(instancesServices), // TODO: rename
        resourcesService: asValue(resourcesService),
        integrationPropertiesService: asValue(integrationPropertiesService),
        serverService: asValue(serverService),
        nodeService: asValue(nodeService),
        imVersionsService: asValue(imVersionsService),
        ssoService: asValue(ssoService),
        passwordsService: asValue(passwordsService),
        integrationManagerUpdateService: asValue(
            integrationManagerUpdateService,
        ),
    });

    registerRematchModels(dic);
    registerApps(dic);
}
