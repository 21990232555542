import { useSftpServerInfoQuery } from "@/components/DataUploads/SftpManagement/loadables";
import { Button, Icon } from "@/components/DesignSystem";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Widget01 from "../../../views/Widgets/Widget01";
import { sftpEntityType } from "@/services/dataLoadSftp.service";

const SFTPServerInfo = ({ dataLoadId, type = sftpEntityType.NEVER }) => {
    const sftpServerInfoResource = useSftpServerInfoQuery({ type, dataLoadId });
    const maybeServerInfo = sftpServerInfoResource.loadable.valueMaybe();

    return (
        <div style={{ maxWidth: "380px", padding: "16px" }}>
            <Widget01
                loading={isLoading(sftpServerInfoResource)}
                items={[
                    {
                        title: t("general.address"),
                        value: maybeServerInfo ? (
                            <div>
                                {maybeServerInfo.url}
                                <CopyToClipboard text={maybeServerInfo.url}>
                                    <Button
                                        type="link"
                                        size="small"
                                        label={<Icon type="copy" />}
                                    />
                                </CopyToClipboard>
                            </div>
                        ) : (
                            <RowSkeleton />
                        ),
                    },
                    {
                        title: t("general.port"),
                        value: maybeServerInfo ? (
                            maybeServerInfo.port
                        ) : (
                            <RowSkeleton />
                        ),
                    },
                ]}
                mainText={t("data-uploads.sftp.server-info.title")}
                color="primary"
            />
        </div>
    );
};

SFTPServerInfo.propTypes = {
    dataLoadId: PropTypes.number.isRequired,
};

export default SFTPServerInfo;
