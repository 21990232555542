import EmailNotificationForm from "@/components/DataUploads/SftpManagement/EmailNotificationForm.component";
import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    Link,
    Table,
    Text,
} from "@/components/DesignSystem";
import { hasValueFn } from "@/components/DesignSystem/Forms/validators";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { AddKeyModal } from "@/components/SFTPServers/AddKeyModal";
import {
    SEPARATOR,
    SSH_KEY_FIELD,
    USERNAME_MAX_LENGTH,
} from "@/components/SFTPServers/constants";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { isLoading } from "@/modules/loadable";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";

const FormFieldValue = ({ formId: formIdProp, name }) => {
    const formId = Forms.useFormId(formIdProp);
    const value = Forms.useFieldValue({ formId, name });

    return value || "";
};

export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9_\-\\.@]*$/);

const createKeysColumns = ({ remove }) => [
    {
        name: "fingerprint",
        label: "Fingerprint",
        render: (text, { id, field }) => (
            <>
                <Forms.Fields.RawValue {...field(SSH_KEY_FIELD.FINGERPRINT)} />
                <ActionButton
                    record={{ id }}
                    items={[
                        {
                            title: t("general.remove"),
                            confirmMessage:
                                "Do you really want to remove the key?",
                            onConfirm: ({ id }) => remove(id),
                            color: "red",
                        },
                    ]}
                />
            </>
        ),
    },
    {
        name: "label",
        label: "Label",
        render: (text, { field }) => (
            <>
                <Forms.Fields.RawValue {...field(SSH_KEY_FIELD.LABEL)} />
                <Forms.Fields.Input
                    {...field(SSH_KEY_FIELD.KEY)}
                    type="hidden"
                    label=""
                />
            </>
        ),
    },
];
const checkPrefixSeparator = maybePrefix =>
    maybePrefix.endsWith("-") ? maybePrefix : maybePrefix + SEPARATOR;

const addPrefix = (maybePrefix, name) =>
    maybePrefix ? checkPrefixSeparator(maybePrefix) + name : name;
const removePrefix = (maybePrefix, name) =>
    maybePrefix && name?.startsWith(checkPrefixSeparator(maybePrefix))
        ? name.replace(checkPrefixSeparator(maybePrefix), "")
        : name;

const SFTPUserForm = ({
    sftpServerQuery,
    sftpServerUserQuery,
    sftpServerUserMutation,
    sftpServerUsernamesLoadable,
    isNew,
    maybePrefix,
    isLDAP,
    initialValues,
    goBack,
    withEmailNotifications = false,
}) => {
    const addKeyModal = useVisibility();

    useSetPageTitle((isNew ? "Add" : "Edit") + " User");

    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const usersList = sftpServerUsernamesLoadable.valueMaybe() ?? [];

    const { isPasswordSet } = initialValues ?? {};

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({ values: { emails, ...values } }) => {
            const mapped = {
                ...values,
                username: addPrefix(maybePrefix, values.username),
                sshPublicKeys: values.sshPublicKeys?.map(
                    obj => obj[SSH_KEY_FIELD.KEY],
                ),
                emails: withEmailNotifications
                    ? emails.map(({ email }) => email).filter(hasValueFn)
                    : undefined,
            };
            const submitValues =
                isChangingPassword && !values.password
                    ? { clearPassword: true, ...mapped }
                    : mapped;
            await sftpServerUserMutation.mutate(submitValues);
        },
    });
    const forbidUsernames = usersList
        .filter(username => username !== initialValues?.username)
        .map(username => removePrefix(maybePrefix, username));
    const hasKey = !!Forms.useFieldValue({ formId, name: "sshPublicKeys" })
        ?.length;

    if (isLoading(sftpServerUserQuery)) return null; // List init needs to be done from prop
    const prefix = addPrefix(maybePrefix, "");
    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Text size="large">
                {isLDAP
                    ? t("sftp-servers.user-management.perex")
                    : t("sftp-servers.user-management.perex-no-ldap")}
            </Text>
            <Forms.Fields.Input
                label={t("sftp-servers.user-management.fields.name.label")}
                placeholder={t(
                    "sftp-servers.user-management.fields.name.placeholder",
                )}
                name="username"
                required
                prefix={prefix}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.noWhiteSpaces,
                    Forms.pmValidators.createPatternValidator(
                        NAME_VALIDATION_PATTERN,
                        "Name has a wrong format. Valid format is a-z A-Z, 0-9 _ - . @ without spaces",
                    ),
                    Forms.pmValidators.createMaxLengthValidation(
                        USERNAME_MAX_LENGTH - prefix.length,
                        t(
                            "sftp-servers.user-management.fields.name.validation.maxLength",
                            { maxLength: USERNAME_MAX_LENGTH },
                        ),
                    ),
                    Forms.pmValidators.createForbidValuesValidation(
                        forbidUsernames,
                        t(
                            "sftp-servers.user-management.fields.name.validation.unique",
                        ),
                    ),
                ])}
                autoComplete="off"
                initialValue={removePrefix(
                    maybePrefix,
                    initialValues?.username,
                )}
                disabled={!isNew}
                {...Forms.Fields.Input.getLoadableProps(sftpServerQuery)}
            />
            {isPasswordSet && !isChangingPassword ? (
                <>
                    <Forms.Fields.InputPassword
                        label={t(
                            "sftp-servers.user-management.fields.password.label",
                        )}
                        disabled={true}
                        value="mockPassword"
                    />
                    <Link
                        onClick={() => setIsChangingPassword(true)}
                        data-test="clear-password"
                    >
                        <T id="sftp-servers.user-management.clear-password" />
                    </Link>
                    <Gap />
                </>
            ) : (
                <Forms.Fields.RestrictedPassword
                    label={t(
                        "sftp-servers.user-management.fields.password.label",
                    )}
                    placeholder={t(
                        "sftp-servers.user-management.fields.password.placeholder",
                    )}
                    name="password"
                    autoComplete="new-password"
                    required={!hasKey}
                />
            )}
            {isLDAP && (
                <Forms.List
                    formId={formId}
                    name="sshPublicKeys"
                    initialValue={initialValues?.sshPublicKeys}
                >
                    {({ rows, add, remove }) => (
                        <>
                            <Table
                                rowKey="id"
                                columns={createKeysColumns({ remove })}
                                dataSource={rows.map(({ id, fieldProps }) => ({
                                    id,
                                    field: fieldProps,
                                }))}
                                pmExpandable={{
                                    expandedRowHeight: 150,
                                    expandedRowRender: ({ field }) => (
                                        <div
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            <FormFieldValue
                                                {...field(SSH_KEY_FIELD.KEY)}
                                            />
                                        </div>
                                    ),
                                }}
                            />
                            <Gap size="small" />
                            <Button
                                onClick={addKeyModal.show}
                                label={t(
                                    "sftp-servers.user-management.add-ssh-key",
                                )}
                                data-test="add-ssh-key"
                            />
                            <AddKeyModal
                                visible={addKeyModal.visible}
                                onAdd={values => {
                                    add(values);
                                    addKeyModal.hide();
                                }}
                                onCancel={addKeyModal.hide}
                            />
                        </>
                    )}
                </Forms.List>
            )}

            <Gap />
            {withEmailNotifications ? (
                <EmailNotificationForm emails={initialValues?.emails} />
            ) : null}

            <ButtonGroup>
                <Forms.SubmitButton
                    formId={formId}
                    disabled={
                        isLoading(sftpServerUserQuery) ||
                        isLoading(sftpServerQuery) ||
                        isLoading(sftpServerUsernamesLoadable)
                    }
                >
                    <Button
                        label={t("general.save")}
                        type="primary"
                        htmlType="submit"
                        data-test="save-button"
                    />
                </Forms.SubmitButton>
                <Button
                    label={t("general.cancel")}
                    type="text"
                    htmlType="button"
                    onClick={goBack}
                />
            </ButtonGroup>
        </Forms.Form>
    );
};

SFTPUserForm.propTypes = {
    sftpServerQuery: PropTypes.object.isRequired,
    sftpServerUserQuery: PropTypes.object.isRequired,
    sftpServerUserMutation: PropTypes.object.isRequired,
    sftpServerUsernamesLoadable: PropTypes.object.isRequired,
    maybePrefix: PropTypes.string,
    isLDAP: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    goBack: PropTypes.func.isRequired,
    withEmailNotifications: PropTypes.bool,
};

export default SFTPUserForm;
